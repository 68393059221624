import styled from 'styled-components';

interface InfoButtonProps {
	$pressed?: boolean;
}

const S = {
	InfoButtonWrapper: styled.div`
		position: relative;
	`,

	Info: styled.div`
		position: relative;
	`,

	InfoContentBox: styled.div`
		z-index: 1100;
		position: fixed;
		background: #fff;
		border-radius: ${(props) => props.theme.radii.default};
		display: block;
		top: 1000vh;
		width: 300px;
		max-width: calc(100vw - 20px);
		max-height: calc(100vh - 20px);
		overflow-y: auto;
		box-shadow: 0px 0px 10px 0px #00000026;
		font-size: ${(props) => props.theme.fontSizes.sm};
		padding: ${(props) => props.theme.spacings.medium};
		display: flex;
		flex-direction: row;
		align-items: center;
		
		span, [data-texttype='markdown'] {
			margin: 0;
		}
	`,

	InfoContent: styled.div`
		flex: 1;

		& * {
			font-size: ${(props) => props.theme.spacings.medium};
			line-height: ${(props) => props.theme.spacings.large};
		}
	`,

	InfoClose: styled.div`
		width: 12px;
		display: flex;
		justify-content: center;
		cursor: pointer;
	`,

	InfoTail: styled.div`
		z-index: 101;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;

		position: fixed;
		top: -10px;
		left: 6px;
	`,

	InfoButton: styled.button.attrs<InfoButtonProps>((props) => ({
		'aria-pressed': props.$pressed
	}))<InfoButtonProps>`
		&& {
			all: unset;
			display: flex;
			cursor: pointer;
			background: #000;
			width: 19px;
			height: 19px;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
			margin-left: ${(props) => props.theme.spacings.small};
			margin-right: ${(props) => props.theme.spacings.small};
			color: #fff;
			font-size: 16px;
			position: relative;
			align-self: flex-start;
			user-select: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;

			&[disabled] {
				cursor: default !important;
				border-radius: 100%;
				border-color: ${(props) => (props.$pressed ? 'none' : props.theme.colors.text300)};
			}

			&:hover:not([disabled]),
			&:focus:not([disabled]) {
				background: ${(props) => props.theme.colors.accent600};
				border-color: ${(props) => props.theme.colors.accent600};
				color: #fff;
				border-radius: 100%;
			}

			&:focus-visible {
				outline: 2px auto #000;
			}
		}
	`
};

export default S;
