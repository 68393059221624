import React, { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react';

import S from './styled';

import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { calculateAgeFromDob } from '../../helpers/support/calculateAgeFromDob';
import { validateDob } from '../../helpers/support/validateDob';
import type { ActivityAnswer, CalculatedAge, DateOfBirth } from '../../models';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

type ValidationStatus = '' | 'valid' | 'invalid' | 'maxAgeExceeded';

type BirthdayInputProps = {
	confirmButtonClassName?: string;
	disabled: boolean;
	step?: number;
	title: string | JSX.Element;
	answer?: { dob?: DateOfBirth; age: CalculatedAge };
	onChange: (answer?: ActivityAnswer) => void;
};

const BirthdayInput = forwardRef<HTMLInputElement, BirthdayInputProps>((props: BirthdayInputProps, ref) => {
	const [{ profile, settings }, dispatch] = useStateContext();

	const defaultDoB: DateOfBirth = props.answer && props.answer.dob ? props.answer.dob : { day: undefined, month: undefined, year: undefined };
	const [dob, setDob] = useState<DateOfBirth>(settings.forceInitialStateEmpty ? defaultDoB : profile.dob);
	const [age, setAge] = useState<CalculatedAge>();
	const monthRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;
	const yearRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;

	const [validationStatus, setValidationStatus] = useState<ValidationStatus>('');

	const handleOnChange = (field: ChangeEvent<HTMLInputElement>) => {
		const { name, value, max, maxLength } = field.target;

		// Rather guard possible properties here than extend DateOfBirth model
		if (name !== 'day' && name !== 'month' && name !== 'year') {
			return;
		}

		const previousValue = dob[name] || '0';
		const currentValue = value.slice(0, field.target.maxLength).replace(/\D/, '');

		const previousInt = parseInt(previousValue);
		const currentInt = parseInt(currentValue);

		setDob({
			...dob,
			[name]: currentValue
		});

		const nextElement = { day: monthRef.current, month: yearRef.current, year: undefined }[name];

		if (nextElement) {
			// First check if numeric input's arrows are used
			// There is no event for this so we assume they are used when the value increments or decrements by 1
			if (currentInt === previousInt + 1 || currentInt === previousInt - 1) {
				// Focus next if current value equals the max value or the user increments by 1 while typing '01' (maxLength)
				if (currentInt === parseInt(max) || (previousValue === '0' && currentValue === '01')) {
					nextElement.focus();
				}
				// Input using keyboard reaches maxLength
			} else if (value.length === maxLength) {
				nextElement.focus();
			}
		}
	};

	useEffect(() => {
		const validDob = validateDob(dob);
		if (validDob) {
			handleActivityResponse();
		} else {
			void props.onChange(undefined);
		}
	}, [age]);

	const handleActivityResponse = () => {
		// Age only has a value if it is valid
		if (!age?.age) {
			setValidationStatus('invalid');
			void props.onChange(undefined);
			return;
		}

		if (age?.ageInYears && age?.ageInYears > settings.maxAge) {
			setValidationStatus('maxAgeExceeded');
			void props.onChange(undefined);
			return;
		}

		void props.onChange({
			dob: dob,
			age: age
		});

		setValidationStatus('valid');
	};

	useEffect(() => {
		const validDob = validateDob(dob);
		if (validDob) {
			const calculatedAge = calculateAgeFromDob(dob, settings.applicationTexts);
			if (calculatedAge) {
				setAge(calculatedAge);
				dispatch({
					type: 'updateProfile/Age',
					dob: dob,
					age: calculatedAge.age
				});

				if (calculatedAge.ageInYears > settings.maxAge) {
					setValidationStatus('maxAgeExceeded');
				} else {
					setValidationStatus('valid');
				}
			} else {
				setValidationStatus('invalid');
			}
		} else {
			// reset values
			setAge(undefined);
			const hasDobInput = dob.day && dob.month && dob.year?.length === 4;
			// Only show error when all dob fields have input
			setValidationStatus(hasDobInput ? 'invalid' : '');
			dispatch({
				type: 'updateProfile/Age',
				dob: { day: null, month: null, year: null },
				age: null
			});
		}
	}, [dob, settings.applicationTexts, settings.maxAge, dispatch]);

	const maxYearInput = new Date().getFullYear();
	const minYearInput = settings.maxAge ? maxYearInput - settings.maxAge : 1900;

	return (
		<>
			<S.Row>
				<S.Col>
					<S.Label htmlFor={`MINDD-Widget-${props.step ?? 0}-AgeDayInput`}>{getLabel('WidgetAgeDayLabel', settings.applicationTexts, true)}</S.Label>
					<S.Input
						ref={ref}
						id={`MINDD-Widget-${props.step ?? 0}-AgeDayInput`}
						disabled={props.disabled}
						autoComplete="off"
						name="day"
						maxLength={2}
						type="number"
						inputMode="numeric"
						pattern="[0-9]*"
						min="1"
						max="31"
						placeholder={getLabel('WidgetAgeDayPlaceholder', settings.applicationTexts, true)}
						value={dob.day ? dob.day : ''}
						onChange={handleOnChange}
					/>
				</S.Col>
				<S.Col>
					<S.Label htmlFor={`MINDD-Widget-${props.step ?? 0}-AgeMonthInput`}>
						{getLabel('WidgetAgeMonthLabel', settings.applicationTexts, true)}
					</S.Label>
					<S.Input
						ref={monthRef}
						id={`MINDD-Widget-${props.step ?? 0}-AgeMonthInput`}
						disabled={props.disabled}
						autoComplete="off"
						name="month"
						maxLength={2}
						type="number"
						inputMode="numeric"
						pattern="[0-9]*"
						min="1"
						max="12"
						placeholder={getLabel('WidgetAgeMonthPlaceholder', settings.applicationTexts, true)}
						value={dob.month ? dob.month : ''}
						onChange={handleOnChange}
					/>
				</S.Col>
				<S.Col>
					<S.Label htmlFor={`MINDD-Widget-${props.step ?? 0}-AgeYearInput`}>
						{getLabel('WidgetAgeYearLabel', settings.applicationTexts, true)}
					</S.Label>
					<S.Input
						ref={yearRef}
						id={`MINDD-Widget-${props.step ?? 0}-AgeYearInput`}
						disabled={props.disabled}
						autoComplete="off"
						name="year"
						maxLength={4}
						type="number"
						inputMode="numeric"
						pattern="[0-9]*"
						min={minYearInput}
						max={maxYearInput}
						placeholder={getLabel('WidgetAgeYearPlaceholder', settings.applicationTexts, true)}
						value={dob.year ? dob.year : ''}
						onChange={handleOnChange}
					/>
				</S.Col>
			</S.Row>

			<ErrorMessage showError={['invalid', 'maxAgeExceeded'].includes(validationStatus)}>
				{validationStatus === 'invalid' && getLabel('WidgetAgeRequestError', settings.applicationTexts)}
				{validationStatus === 'maxAgeExceeded' && getLabel('WidgetMaxAgeRequestError', settings.applicationTexts)}
			</ErrorMessage>
		</>
	);
});

BirthdayInput.displayName = 'BirthdayInput';
export default BirthdayInput;
