import React, { useEffect, useRef, useState, useMemo, FunctionComponent } from 'react';
import S from './styled';
import SectionContainer from '../SectionContainer';
import LanguageSelector from '../LanguageSelector';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import type { ActivityType, Profile, Settings } from '../../models';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';
import { useTheme } from 'styled-components';

const Widget: FunctionComponent = () => {
	const [{ modal, settings, refs, profile, currentSection }, dispatch] = useStateContext();
	const handleToggleModal = useToggleModal();
	const [scrollbarWidth, setScrollbarWidth] = useState(0);
	const widgetRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
	const headerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
	const [, widgetIsSmall] = useWidgetIsNarrow();
	const themeContext = useTheme();

	useEffect(() => {
		dispatch({
			type: 'updateRefs',
			refs: {
				...refs,
				widget: widgetRef,
				header: headerRef
			}
		});

		// Calculate scrollbar width
		const widgetElement = widgetRef.current;
		if (widgetElement) {
			const scrollbarWidth = widgetElement.offsetWidth - widgetElement.clientWidth;
			setScrollbarWidth(scrollbarWidth);
		}
	}, [dispatch]);

	const renderLogo = () => {
		if (settings.logoUrl) {
			return <img src={settings.logoUrl} alt={settings.branding?.name} />;
		}
		return null;
	};
	const hasHeader = useMemo(
		() => modal.open && !['advice', 'requestCallbackEnd'].includes(currentSection?.type ?? ''),
		[modal.open, settings.branding, settings.branding?.layout, currentSection]
	);

	return (
		<React.Fragment>
			{modal.open && <S.WidgetHeader>{getLabel('WidgetHeaderText', settings.applicationTexts, true)}</S.WidgetHeader>}
			{hasHeader && (
				<>
					<S.Header ref={headerRef}>
						<S.MainHeader $scrollbarWidth={scrollbarWidth}>
							<S.MainHeaderInner>
								<S.ModalCloseButton
									onClick={() => void handleToggleModal(false)}
									title={getLabel('WidgetModalCloseButtonTitle', settings.applicationTexts, true)}
									className={'mindd-widget-clossButton mindd-widget-closeButton'}
								>
									<FontAwesomeIcon icon={faXmark} style={{ fontSize: '20px' }} />
								</S.ModalCloseButton>

								<S.HeaderPractice>
									{settings.logoUrl && <S.HeaderInnerLogo>{renderLogo()}</S.HeaderInnerLogo>}
									<S.PracticeInfo>
										<S.PracticeName title={settings.branding?.name}>{settings.branding?.name}</S.PracticeName>
										{/* TODO: Opening times */}
										{false && <S.PracticeTimes>Momenteel gesloten</S.PracticeTimes>}
									</S.PracticeInfo>
								</S.HeaderPractice>
							</S.MainHeaderInner>
						</S.MainHeader>
					</S.Header>
					<S.SectionHeaderWrapper>
						<S.SectionHeader $scrollbarWidth={scrollbarWidth} data-testid="widget-section-heading">
							<S.SectionHeaderInner>
								{currentSection && <S.SectionTitle>{getHeading(currentSection.type, settings, profile)}</S.SectionTitle>}
							</S.SectionHeaderInner>
						</S.SectionHeader>
					</S.SectionHeaderWrapper>
				</>
			)}
			<S.Widget
				$mode={settings.modalDisplayMode}
				$hasBranding={!!(settings.branding && settings.branding.layout)}
				ref={widgetRef}
				$modalOpen={modal.open ?? undefined}
				$hasHeader={hasHeader ?? undefined}
				$small={widgetIsSmall}
			>
				<S.Body>
					{!modal.open && (
						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: themeContext.spacings.medium }}>
							{/* TODO: opening times */}
							<div />
							{settings.showLanguageSelector && settings.useLanguageSelector && <LanguageSelector />}
						</div>
					)}
					{/* TODO: volgens mij kan deze weg {modal.open && !hasHeader && <S.CloseButtonBar hasAdvice={conversationHasAdvice} />} */}
					<SectionContainer />
				</S.Body>
			</S.Widget>
		</React.Fragment>
	);
};

export default Widget;

function getHeading(sectionType: ActivityType, settings: Settings, profile: Profile) {
	let label = '';
	switch (sectionType) {
		case 'genderSelector':
			label = 'SectionHeadingGender'; // 'Geslacht'
			break;
		case 'ageSelector':
			label = 'SectionHeadingAge'; // 'Leeftijd'
			break;
		case 'bodyAreaSelector':
			label = 'SectionHeadingComplaint'; // 'Klacht'
			break;
		case 'complaintSelector':
			if (profile.bodypart && profile.bodypartName) {
				label = 'SectionHeadingComplaint';
				const heading = getLabel(label, settings.applicationTexts, true);
				const bodyPartName = (profile.bodypartName ?? '').toLowerCase();
				return `${heading} ${bodyPartName}`; // e.g. 'Klacht Hoofd'
			} else {
				label = 'SectionHeadingComplaint'; // 'Klacht'
			}
			break;
		case 'triageNormal':
			label = 'SectionHeadingTriage'; // 'Vragen over uw klacht'
			break;
		case 'triageABCD':
		case 'abcdTriage':
			label = 'SectionHeadingABCD'; // 'Controlevragen'
			break;
		case 'advice':
			label = 'AdviceOnsAdvies'; // 'Advies / 'Ons advies'
			break;
		case 'consultationPreparation':
			label = 'SectionHeadingConsultationPreparation'; // 'Consultvoorbereiding'
			break;
		case 'requestCallback':
			label = 'callbackRequestFormTitle'; // 'Contactgegevens' / 'Uw contactgegevens'
			break;
		case 'callbackRequestScheduler':
			label = 'SectionHeadingCallbackRequestTimeSlot'; // 'Terugbelverzoek'
			break;
		case 'askGPPost':
			label = 'SectionHeadingAskGPPost'; // 'Huisartsenpost'
			break;
		default:
			return sectionType; // should never happen
	}

	return getLabel(label, settings.applicationTexts, true);
}
