import { useCallback } from 'react';

import { useStateContext } from '../useStateContext';
import type { ActivityStep } from '../../../models';
import { CallSurveyGetNext } from '../../services';

export type UseNextStepSurveyResultType = (questionId: number, answer: number, answerText: string | undefined) => Promise<ActivityStep>;

export const useNextStepSurvey: () => UseNextStepSurveyResultType = () => {
	const [{ session, settings }] = useStateContext();

	return useCallback(
		async (questionId: number, answer: number, answerText: string | undefined): Promise<ActivityStep> => {
			const nextQuestion = await CallSurveyGetNext(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				questionId: questionId,
				answerId: answer,
				answerText: answerText,
				languageCode: settings.selectedLanguage.code
			});

			if (nextQuestion && nextQuestion.question) {
				if (nextQuestion.question.type === 'Inquiry') {
					// Inquiry
					return {
						step: 0,
						id: `survey-${nextQuestion.question.id}`,
						type: 'surveyInquiry',
						question: nextQuestion.question
					};
				} else {
					return {
						step: 0,
						id: `survey-${nextQuestion.question.id}`,
						type: 'surveyQuestion',
						question: nextQuestion.question
					};
				}
			} else {
				return {
					step: 0,
					id: `survey-end`,
					type: 'surveyEnd'
				};
			}
		},
		[session, settings.ApiKey, settings.selectedLanguage]
	);
};
