import { createGlobalStyle } from 'styled-components';
import type { ModalContext, Settings } from '../../models';

/**
 * A helper function to generate a special StyledComponent that handles global styles.
 * Normally, styled components are automatically scoped to a local CSS class and therefore
 * isolated from other components. In the case of createGlobalStyle, this limitation is
 * removed and things like CSS resets or base stylesheets can be applied.
 *
 * ie. not scoped!!!
 *
 * https://www.styled-components.com/docs/api#createglobalstyle
 *
 */

export const GlobalStyle = createGlobalStyle<{ settings: Settings; modal: ModalContext }>`	
	// reset
	div[data-minddclass='nonmodal'], div[data-minddclass='modal'] {
		*,
		*::before,
		*::after {
			box-sizing: border-box;
		}
	}

	/* Styling for the ReactToolTip */
	div[data-minddclass='widget'] .__react_component_tooltip {
		display: inline-block;
		font-size: 13px;
		left: -999em;
		opacity: 0;
		position: fixed;
		pointer-events: none;
		transition: opacity 0.3s ease-out;
		top: -999em;
		visibility: hidden;
		z-index: 999;

		color: #fff;
		background: #222;
		border: 1px solid transparent;
		border-radius: 3px;
		padding: 8px 21px;

		&.allow_click {
			pointer-events: auto;
		}

		&::before, &::after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
		}

		&.show {
			opacity: 0.9;
			margin-top: 0;
			margin-left: 0;
			visibility: visible;
		}

		&.place-top {
			margin-top: -10px;

			&::before {
				bottom: 0;
				left: 50%;
				margin-left: -11px;

				content: "";
				background-color: inherit;
				position: absolute;
				z-index: 2;
				width: 20px;
				height: 12px;
			}

			&::after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				border-top-right-radius: 0px;
				border: 1px solid transparent;
				background-color: #222;
				z-index: -2;
				bottom: -6px;
				left: 50%;
				margin-left: -6px;
				transform: rotate(135deg);
			}
		}

		&.place-bottom {
			margin-top: 10px;

			&::before {
				top: 0;
				left: 50%;
				margin-left: -11px;

				content: "";
				background-color: inherit;
				position: absolute;
				z-index: -1;
				width: 18px;
				height: 10px;
			}

			&::after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				border-top-right-radius: 0px;
				border: 1px solid transparent;
				background-color: #222;
				z-index: -2;
				top: -6px;
				left: 50%;
				margin-left: -6px;
				transform: rotate(45deg);
			}
		}

		&.place-left {
			margin-left: -10px;
			
			&::before {
				right: 0;
				top: 50%;
				margin-top: -9px;

				content: "";
				background-color: inherit;
				position: absolute;
				z-index: -1;
				width: 10px;
				height: 18px;
			}

			&::after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				border-top-right-radius: 0px;
				border: 1px solid transparent;
				background-color: #222;
				z-index: -2;
				right: -6px;
				top: 50%;
				margin-top: -6px;
				transform: rotate(45deg);
			}
		}

		&.place-right {
			margin-left: 10px;

			&::before {
				left: 0;
				top: 50%;
				margin-top: -9px;

				content: "";
				background-color: inherit;
				position: absolute;
				z-index: -1;
				width: 10px;
				height: 18px;
			}

			&::after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				border-top-right-radius: 0px;
				border: 1px solid transparent;
				background-color: #222;
				z-index: -2;
				left: -6px;
				top: 50%;
				margin-top: -6px;
				transform: rotate(-135deg);
			}
		}

		.multi-line {
			display: block;
			padding: 2px 0;
			text-align: center;
		}
	}
`;
