// TODO: improve styling
import styled from 'styled-components';

const Buttons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${(props) => props.theme.spacings.small};
`;

const StartIntroLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.theme.colors.text};
	gap: ${(props) => props.theme.spacings.small};
	margin-bottom: ${(props) => props.theme.spacings.medium};
	font-size: 14px;
`;

const Button = styled.button`
	cursor: pointer;
	padding: 10px ${(props) => props.theme.spacings.large};
	font-weight: initial !important;
	text-transform: initial !important;
	transition: initial !important;
	font-size: initial !important;
	text-align: center;
	margin-right: 0;
	width: 100%;
	border: none !important;
	border-radius: ${(props) => props.theme.radii.default};
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	color: ${(props) => props.theme.colors.text100};
	background: ${(props) => props.theme.colors.accent};
	
	&:hover {
		border: none;
	}

	// TODO: use other colors so widget looks better on first impression?
	&:hover:not([disabled]) {
		color: ${(props) => props.theme.colors.text100};
		background: ${(props) => props.theme.colors.accent600};
	}

	&[disabled] {
		color: ${(props) => props.theme.colors.text700};
		background: ${(props) => props.theme.colors.text300};
	}
`;

export default {
	Buttons,
	Button,
	StartIntroLabel
};
