import { DateOfBirth } from '../DateOfBirth';
import { Gender } from '../Gender';
import { Language } from '../services/Language';

export type WidgetType = 'widget' | 'button';

export type WidgetModalDisplayMode = 'dialog' | 'full';

export type WidgetStartQuestionType = 'gender' | 'age';

export type WidgetFontFamilies = 'Montserrat' | 'Arial' | 'Verdana' | 'Calibri' | 'Roboto';

export type LanguageApplicationTexts = {
	[key: string]: string; // Key can be any of the supported languages (NL, EN, etc.) but we do not know all beforehand
};

export type WidgetConfig = {
	ApiKey: string;
	target?: string | Element;
	type?: WidgetType;
	accent_color?: string;
	welcome_text?: string | LanguageApplicationTexts;
	open: boolean;
	modalDisplayMode?: WidgetModalDisplayMode;
	widget_showWelcomeText: boolean;
	widget_background?: string;
	widget_foreground?: string;
	clickOutsideModalClosesModal?: boolean;
	showLanguageSelector?: boolean;
	defaultLanguageCode?: string;
	getSessionSummaryUsingForm?: boolean;
	startQuestionType?: WidgetStartQuestionType;
	startWithTriageSearch?: boolean | 'forceTrue';
	startWithAbcdTriage?: boolean;
	labels?: {
		QuestionWhatGender?: string | LanguageApplicationTexts;
		QuestionWhatSearchTriage?: string | LanguageApplicationTexts;
	};
	branding?: {
		termsOfUseUrl?: string;
		layout?: 'top' | null;
		name?: string;
		phone_label?: string;
		phone_number?: string;
		font_family?: WidgetFontFamilies;
		hide_footer?: boolean;
	};
	hide_footer?: boolean;
	onWidgetOpened?: () => void;
	onWidgetClosed?: (finished: boolean, sessionId: string, urgence: string | null) => void;
	onLanguageChanged?: (previousLanguage: Language, newLanguage: Language) => void;
	onAdviceShown?: (adviceId: number) => void;
	onAdvicePdfRetrieval?: (downloadUrl: string, urgence: string) => void;
	forceInitialStateEmpty?: boolean;
	sessionTimeOutInSec?: number;
	prefill?: {
		active: boolean;
		dob?: DateOfBirth;
		gender?: Gender;
		bodypart?: number;
		complaint?: number;
	};
};

export const DEFAULT_SEARCH_DEBOUNCE_DELAY = 250;
