import React, { useEffect, useMemo, useCallback, useState, FunctionComponent } from 'react';
import debounce from 'lodash.debounce';

import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import S from './styled';

import ActivityWelcome from '../ActivityWelcome/ActivityWelcome';
import Logo from '../Logo/Logo';
import TermsOfUse from '../TermsOfUse/TermsOfUse';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import { CallPostSearchTriageProgress, CallSearchTriages, CallStartSession } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import { getOverrideLabel } from '../../helpers/constants/getOverrideLabel';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { DEFAULT_SEARCH_DEBOUNCE_DELAY } from '../../models/widget/WidgetConfig';
import type { ActivityProps } from '../Activity';
import type { SearchTriageHit } from '../../models';

interface ActivitySearchTriageProps extends ActivityProps {
	title?: string | JSX.Element;
	empathy?: string;
}

const ActivitySearchTriage: FunctionComponent<ActivitySearchTriageProps> = (props) => {
	const [{ profile, settings, session }, dispatch] = useStateContext();
	const handleToggleModal = useToggleModal();
	const [searchTriageTitle, setSearchTriageTitle] = useState('');
	const [triageHits, setTriageHits] = useState<SearchTriageHit[]>([]);
	const [selectedTriageHit, setSelectedTriageHit] = useState<SearchTriageHit>();

	const label = settings.labels?.QuestionWhatSearchTriage;
	const bubbleText = getOverrideLabel(label, props.title ?? '', settings.selectedLanguage.code);

	const debouncedFetchTriagesFor = useMemo(() => {
		const fetchTriagesFor = async (stt: string) => {
			const searchTriageHits = await CallSearchTriages(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				searchTitle: stt.trim(),
				languageCode: settings.selectedLanguage.code
			});

			setTriageHits(searchTriageHits);
		};

		return debounce(fetchTriagesFor, DEFAULT_SEARCH_DEBOUNCE_DELAY);
	}, [session, settings.ApiKey, settings.selectedLanguage.code]);

	useEffect(() => {
		if (searchTriageTitle && searchTriageTitle.trim().length > 2) {
			void debouncedFetchTriagesFor(searchTriageTitle);
		} else {
			debouncedFetchTriagesFor.cancel();
			setTriageHits([]);
		}
	}, [searchTriageTitle, debouncedFetchTriagesFor]);

	// Stop the invocation of the debounced function after unmounting
	useEffect(() => {
		return () => {
			debouncedFetchTriagesFor.cancel();
		};
	}, [debouncedFetchTriagesFor]);

	const handleChangeSearchTitleField = (field: React.ChangeEvent<HTMLInputElement>) => {
		if (props.modalOpen) {
			setSearchTriageTitle(field.target.value);
		}
	};

	const handleFocusSearchTitleField = () => {
		if (!props.modalOpen) {
			void CallStartSession(settings.ApiKey, {
				widgetType: settings.widgetType,
				restart: false,
				externalId: profile.externalId,
				languageCode: settings.selectedLanguage.code
			}).then((data) => {
				if (data && data.sessionId) {
					dispatch({
						type: 'updateSession',
						session: {
							id: data.sessionId,
							token: data.sessionToken
						}
					});

					void handleToggleModal();
				} else {
					console.error('There was an error starting a session');
				}
			});
		}
	};

	const focusLastActivity = useCallback(
		(node: HTMLInputElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				setTimeout(() => {
					node.focus();
					node.select();
				}, 50);
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleActivityResponse = async (triageHit: SearchTriageHit) => {
		setSelectedTriageHit(triageHit);

		// If there is only 1 triage group select it and continue with it
		const triageGroup = triageHit.groups !== null && triageHit.groups.length === 1 ? triageHit.groups[0] : null;

		dispatch({
			type: 'updateProfile',
			profile: {
				...profile,
				selectedSearchTriageHit: triageHit,
				selectedSearchTriageGroup: triageGroup
			}
		});

		await CallPostSearchTriageProgress(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			hitChoosen: triageHit.searchHitText,
			triageChoosen: triageGroup?.groupName,
			askForBodyPart: triageGroup?.askForBodyPart ?? false,
			askForGender: triageGroup?.askForGender ?? false,
			askForAge: triageGroup?.askForAge ?? false
		});

		await props.handleActivityResponse({ triageHit: triageHit, triageGroup: triageGroup });
	};

	return (
		<SA.ActivityGroup>
			{props.modalOpen && props.isFirstActivity && <TermsOfUse modalOpen={props.modalOpen} />}
			{(settings.widget_showWelcomeText || props.modalOpen) && props.isFirstActivity && <ActivityWelcome modalOpen={props.modalOpen} />}

			{props.empathy && (
				<SA.ActivityBubble>
					<S.EmpathyBubbleTitle>{renderMarkdown(props.empathy)}</S.EmpathyBubbleTitle>
				</SA.ActivityBubble>
			)}
			<S.ActivityBubble>
				{props.isFirstActivity && settings.showLanguageSelector && settings.useLanguageSelector && <S.LanguageSelector disabled={props.disabled} />}
				<S.BubbleTitle>{bubbleText}</S.BubbleTitle>

				<S.Form>
					<SP.Row>
						<S.TriageSearchContainer>
							<SP.FieldRound
								ref={focusLastActivity}
								disabled={props.disabled}
								type="text"
								className={'mindd-widget-startbutton'}
								onChange={handleChangeSearchTitleField}
								onFocus={handleFocusSearchTitleField}
								name="searchTriageTitle"
								value={searchTriageTitle}
								autoComplete="off"
								placeholder={getLabel('WidgetSearchTriagePlaceholder', settings.applicationTexts, true)}
							/>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path
									d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
									fill="#60606a"
								/>
							</svg>
						</S.TriageSearchContainer>
					</SP.Row>
				</S.Form>

				{props.modalOpen && (
					<S.Complaints>
						<S.ComplaintsTitle>{getLabel('WidgetSearchTriageResults', settings.applicationTexts)}</S.ComplaintsTitle>
						{triageHits.length === 0 && (
							<S.ComplaintsEmpty>
								{searchTriageTitle && searchTriageTitle.trim().length > 2
									? getLabel('WidgetSearchTriageNoResults', settings.applicationTexts)
									: getLabel('WidgetSearchTriageEmptyTitle', settings.applicationTexts)}
							</S.ComplaintsEmpty>
						)}
						<S.ComplaintsList>
							{triageHits &&
								triageHits.map((item, index) => {
									return (
										<S.Complaint key={index}>
											<S.ComplaintButton
												disabled={props.disabled}
												onClick={() => !props.disabled && void handleActivityResponse(item)}
												$selected={item.searchHitText === selectedTriageHit?.searchHitText}
											>
												{item.searchHitText}
											</S.ComplaintButton>
										</S.Complaint>
									);
								})}
						</S.ComplaintsList>
					</S.Complaints>
				)}

				{!props.modalOpen && !settings.hide_footer && props.isFirstActivity && <Logo align="end" size={145} />}
			</S.ActivityBubble>
			{!props.modalOpen && props.isFirstActivity && <TermsOfUse modalOpen={props.modalOpen} />}
		</SA.ActivityGroup>
	);
};

export default ActivitySearchTriage;
