import React, { ReactNode } from 'react';

import styled from 'styled-components';

interface ContactRequestContainerProps {
	isVisible: boolean;
	children: ReactNode;
}

const ContactButtonContainer = styled.div<{ $isVisible: boolean }>`
	position: fixed;
	z-index: 50;
	left: 0;
	right: 0;
	bottom: 0;
	transform: ${(props) => (props.$isVisible ? 'translateY(0%)' : 'translateY(100%)')};
	border-top: solid 1px rgba(0, 0, 0, 0.1);
	transition: transform 0.5s ease-in-out;
	background: #fff;
	padding: 15px ${(props) => props.theme.spacings.medium} 10px ${(props) => props.theme.spacings.medium};

	@media ${(props) => props.theme.screens.medium} {
		position: unset;
		z-index: unset;
		left: unset;
		right: unset;
		bottom: unset;
		transform: unset;
		border-top: unset;
		transition: unset;
		background: unset;
		padding: unset;
	}
`;

const ContactRequestContainer = React.forwardRef<React.MutableRefObject<HTMLDivElement>, ContactRequestContainerProps>(({ children, isVisible }, ref) => (
	// @ts-expect-error ref
	<ContactButtonContainer $isVisible={isVisible} ref={ref}>
		{children}
	</ContactButtonContainer>
));

export default ContactRequestContainer;
