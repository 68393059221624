import type { ThemeType } from '../../models';

const SLIDE_DURATION = 400;
const ANIMATION_STEP_DELAY = 5;

export default (node: HTMLElement, theme: ThemeType, container?: HTMLElement, scrollOffset = 0) => {
	const SLIDE_DISTANCE = `calc(30 * ${theme.spacings.medium})`;

	// Delay animation by 0ms to fix weird scroll behaviour on safari
	setTimeout(() => {
		// Animate with js for smoother animation
		const containerHeight = container?.getBoundingClientRect().height ?? 0;
		const containerScrollHeight = container?.scrollHeight ?? 0;

		// Scroll bottom of new node to bottom of container
		// @ts-expect-error we know this value will never be null
		const marginBottom = parseInt(theme.spacings.medium.match(/\d*/));
		const newScrollTop = containerScrollHeight - containerHeight - marginBottom + scrollOffset; // Leave 1rem margin at the bottom

		// Margin to handle floating-point precision issues
		const epsilon = 0.1;
		// If newly added element fits fully on screen without scrolling, temporarily disable scrollbar on the container
		let newElementFitsOnScreen = false;
		if (containerScrollHeight <= containerHeight + epsilon) {
			newElementFitsOnScreen = true;
			container && (container.style.overflowY = 'hidden');
		}

		node.style.marginTop = SLIDE_DISTANCE;

		// Force a reflow to ensure the initial style takes effect
		node.getBoundingClientRect();

		setTimeout(() => {
			node.style.transition = `margin-top ${SLIDE_DURATION}ms cubic-bezier(.51,.75,.6,.95)`;

			setTimeout(() => {
				node.style.marginTop = theme.spacings.extraLarge;
				if (!newElementFitsOnScreen) {
					container && container.scrollTo({ top: newScrollTop });
				}

				setTimeout(() => {
					// Re-enable scroll
					if (newElementFitsOnScreen) {
						container && (container.style.overflowY = 'auto');
					}
				}, SLIDE_DURATION + ANIMATION_STEP_DELAY);
			}, ANIMATION_STEP_DELAY);
		}, ANIMATION_STEP_DELAY);
	});
};
