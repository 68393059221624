import React from 'react';
import styled from 'styled-components';
import { default as SP } from '../Procedure/Procedure.styled';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import type { AdvicePhoneNumber } from '../../models';
import useDeviceDetection from '../../helpers/hooks/useDeviceDetection';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { IconButton } from '../Button/Button';

interface PhoneNumberListProps {
	activePhoneNumbers: AdvicePhoneNumber[];
	handleCall: (phoneNumber: string) => void;
}

const PhoneNumberRow = styled.div`
	&&& {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;

const PhoneNumberRowWrapper = styled.div`
	&&& {
		display: flex;
		flex-direction: column;

		&:not(:last-child) {
			border-bottom: solid 2px ${(props) => props.theme.colors.borderLight};
			padding-bottom: ${(props) => props.theme.spacings.small};
		}
		&:not(:first-child) {
			padding-top: ${(props) => props.theme.spacings.small};
		}
	}
`;

const PhoneNumberLabel = styled(SP.Label)`
	&&& {
		font-weight: 600;
		line-height: 1.5;
	}
`;

const PhoneNumberList = ({ activePhoneNumbers, handleCall }: PhoneNumberListProps) => {
	const [{ settings }] = useStateContext();
	const isMobileDevice = useDeviceDetection();

	return (
		<>
			{activePhoneNumbers.map((phoneNumber, index) => (
				<PhoneNumberRowWrapper key={index}>
					<PhoneNumberRow>
						<div>
							<PhoneNumberLabel>{renderMarkdown(phoneNumber.label)}</PhoneNumberLabel>
							{renderMarkdown(phoneNumber.phoneNumber)}
						</div>
						{isMobileDevice && (
							<IconButton onClick={() => handleCall(phoneNumber.phoneNumber)} $icon={faPhone} $position={'before'}>
								{getLabel('NativeButtonCall', settings.applicationTexts, true)}
							</IconButton>
						)}
					</PhoneNumberRow>
				</PhoneNumberRowWrapper>
			))}
		</>
	);
};

export default PhoneNumberList;
