import React from 'react';
import styled from 'styled-components';

import { default as SA } from '../Activity/Activity.styled';

// Design defined constants
const HEADER_HEIGHT = 72;
const HEADER_ICON_SIZE = 40;
const ADVICE_IMAGE_HEIGHT = 240;
const DESKTOP_ADVICE_CONTENT_MAX_WIDTH = 960;

interface HeaderImageProps {
	$backgroundImage: string;
}

interface ActivityGroupProps {
	$padding?: number;
}

const ActivityGroup = styled(SA.ActivityGroup)<ActivityGroupProps>`
	${SA.Activity}:last-child & {
		padding-bottom: ${(props) => props.$padding ?? '160'}px;
	}

	display: flex;
	flex-direction: column;
	margin: 0 -${(props) => props.theme.spacings.medium};
	scroll-behavior: smooth;
`;

const ActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		max-height: none;
	}
	margin: 0 !important;
	padding: ${(props) =>
		`${props.theme.spacings.large} ${props.theme.spacings.medium} ${props.theme.spacings.large} ${props.theme.spacings.medium}`} !important;
	border-top: solid 4px ${(props) => props.theme.colors.accent100};
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;

	&:first-child {
		border-top: none;
		border-bottom: solid 4px ${(props) => props.theme.colors.accent100};
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;

		& p:last-child {
			margin-bottom: 0;
		}
	}

	&:last-of-type {
		border-bottom: solid 4px ${(props) => props.theme.colors.accent100};
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	@media ${(props) => props.theme.screens.medium} {
		/* border-top: solid 1px #0000000d; */
		border-top: none;
		border-bottom: none;
		border: none !important;
		padding: 0 !important;
		margin: ${(props) => props.theme.spacings.extraExtraExtraLarge} 0 !important;

		&:first-child {
			margin-top: ${(props) => props.theme.spacings.extraLarge} !important;
		}
	}
`;

const ActivityBubbleContactRequestProcedure = styled(ActivityBubble)`
	border: none !important;
	padding: 0 ${(props) => props.theme.spacings.medium} !important;

	@media ${(props) => props.theme.screens.medium} {
		padding: 0 !important;
	}
`;

const BubbleHeaderComplaint = styled.div`
	font-size: ${(props) => props.theme.fontSizes.sm};
	margin-top: ${(props) => props.theme.spacings.medium};
	color: ${(props) => props.theme.colors.text700};

	@media ${(props) => props.theme.screens.medium} {
		margin-top: 0;
	}
`;

const AdviceHeader = styled.div`
	margin-top: -${(props) => props.theme.spacings.medium};
	margin-bottom: ${(props) => props.theme.spacings.medium};
	height: ${HEADER_HEIGHT}px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: ${(props) => props.theme.spacings.small};
	z-index: 100;
	width: 100%;
	padding: ${(props) => props.theme.spacings.small};
`;

const AdviceHeaderButton = styled.button`
	padding: ${(props) => `${props.theme.spacings.small} ${props.theme.spacings.smallMedium}`};

	background-color: ${(props) => props.theme.colors.accent100};
	border: solid 1px rgba(0, 0, 0, 0.1);
	border-radius: ${(props) => props.theme.radii.default};

	color: ${(props) => props.theme.colors.text900};
	font-weight: 400;
	font-size: ${(props) => props.theme.fontSizes.sm};
	display: flex;
	flex-direction: row;
	gap: ${(props) => props.theme.spacings.small};
	align-items: center;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	outline-color: ${(props) => props.theme.colors.accent} !important;

	&:hover,
	&:focus {
		border: solid 1px rgba(0, 0, 0, 0.1);
		outline: none;
		background-color: ${(props) => props.theme.colors.accent200};
	}
`;

const AdviceRestart = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: ${(props) => props.theme.spacings.small};
	color: ${(props) => props.theme.colors.accent};
	text-decoration: underline;

	&:hover {
		cursor: pointer;
		color: ${(props) => props.theme.colors.accent600};
	}
`;

const AdviceHeaderTitle = styled.div`
	font-size: ${(props) => props.theme.fontSizes.xl2};
	color: ${(props) => props.theme.colors.text900};
	font-weight: 600;
`;

const AdviceHeaderIcon = styled.div`
	border-radius: ${(props) => props.theme.radii.default};
	background-color: ${(props) => props.theme.colors.accent100};
	padding: ${(props) => props.theme.spacings.small};
	margin: ${(props) => props.theme.spacings.smallMedium};
	height: ${HEADER_ICON_SIZE}px;
	aspect-ratio: 1;
	text-align: center;
`;

const GeneralAdvice = styled.div`
	&&& {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex: 0 1 auto;
		padding: ${(props) => `${props.theme.spacings.medium} ${props.theme.spacings.medium} 0 ${props.theme.spacings.medium}`} !important;
		background-color: ${(props) => props.theme.colors.accent100};
		margin-top: -${(props) => props.theme.spacings.medium};
		border-bottom: solid 2px ${(props) => props.theme.colors.accent100};

		p {
			margin: 0;
		}

		@media ${(props) => props.theme.screens.medium} {
			margin-top: ${(props) => props.theme.spacings.medium};
			border-radius: ${(props) => props.theme.radii.default};
			height: ${ADVICE_IMAGE_HEIGHT}px;
		}
	}
`;

const GeneralAdviceAdvice = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	gap: ${(props) => props.theme.spacings.small};
	max-width: ${DESKTOP_ADVICE_CONTENT_MAX_WIDTH}px;

	@media ${(props) => props.theme.screens.medium} {
		flex-direction: row;
	}
`;

const GeneralAdviceAdviceContent = styled.div`
	@media ${(props) => props.theme.screens.medium} {
		max-width: calc(100% - 300px);
	}

	color: ${(props) => props.theme.colors.text900};
`;

const AdviceTitle = styled.h3`
	color: ${(props) => props.theme.colors.text900};
`;

const AdviceIntro = styled.div`
	color: ${(props) => props.theme.colors.text700};
	margin-bottom: ${(props) => props.theme.spacings.small};
`;

const AdviceContent = styled.div`
	width: 100%;
	display: flex;
	gap: ${(props) => props.theme.spacings.small};
	flex-direction: column;
	position: relative;

	@media ${(props) => props.theme.screens.medium} {
		max-width: 960px;
		align-self: center;
		flex-direction: row-reverse;
	}
`;

const Advice = styled.div`
	@media ${(props) => props.theme.screens.medium} {
		flex: 3;
		max-width: 75%;
		padding-top: ${(props) => props.theme.spacings.large};
	}
`;

const TableOfContents = styled.div`
	align-self: flex-start;
	margin-top: 0;
	padding: ${(props) => props.theme.spacings.small};
	position: sticky;
	top: 0;
	padding-top: ${(props) => props.theme.spacings.small};
	border-radius: 0;
	display: flex;
	flex-direction: column;
	color: ${(props) => props.theme.colors.text900};
	background-color: #fff;
	z-index: 10;
	width: 100%;
	border-bottom: solid 4px ${(props) => props.theme.colors.accent100};

	@media ${(props) => props.theme.screens.medium} {
		margin-top: ${(props) => props.theme.spacings.medium};
		flex: 1;
		max-width: 25%;
		border-bottom: none;
	}
`;

const Nav = styled.ol`
	padding-inline-start: 0;
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacings.extraSmall};
`;

const NavItem = styled.li`
	list-style-type: none;
	border-radius: ${(props) => props.theme.radii.default};
	cursor: pointer;
	padding: ${(props) => `${props.theme.spacings.extraSmall} ${props.theme.spacings.small}`};
	color: ${(props) => props.theme.colors.text700};
	width: 100%;

	&:hover {
		@media ${(props) => props.theme.screens.medium} {
			color: ${(props) => props.theme.colors.text900};
			background-color: ${(props) => props.theme.colors.accent100};
			transition: color 0.2s ease-in-out background-color 0.2 ease-in-out;
		}
	}

	&:active,
	&.active {
		color: ${(props) => props.theme.colors.text900};
		background-color: ${(props) => props.theme.colors.accent100};
		transition: color 0.2s ease-in-out background-color 0.2 ease-in-out;
	}
`;

const ActivityActionsContactButtons = styled.div`
	&&& {
		display: flex;
		justify-content: start;
		flex-wrap: wrap;
		gap: ${(props) => props.theme.spacings.extraSmall};
		margin: ${(props) => props.theme.spacings.smallMedium} 0;
		flex-direction: column-reverse;

		@media ${(props) => props.theme.screens.medium} {
			gap: ${(props) => props.theme.spacings.small};
			margin: ${(props) => props.theme.spacings.medium} 0;
			flex-direction: row;
		}
	}
`;

const CallbackRequestButton = styled.button`
	background-color: ${(props) => props.theme.colors.accent};
	border-radius: ${(props) => props.theme.radii.default};
	color: ${(props) => props.theme.colors.text100};
	padding: ${(props) => `${props.theme.spacings.small} ${props.theme.spacings.large}`};
	transition: background-color 100ms ease-in-out;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	border: none;
	font-size: ${(props) => props.theme.fontSizes.base};
	font-weight: 600;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	&:hover:not([disabled]) {
		background-color: ${(props) => props.theme.colors.accent600};
		color: ${(props) => props.theme.colors.text100};
		border: none;
	}
`;

const ShowPhoneNumbersButton = styled(AdviceHeaderButton)`
	&&& {
		border-color: ${(props) => props.theme.colors.borderLight};
		font-weight: 600;
		font-size: initial;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: ${(props) => props.theme.spacings.medium};
	}
`;

const PhoneNumberPanelLabel = styled(SA.BubbleSubTitle)`
	&&& {
		margin-bottom: ${(props) => props.theme.spacings.small};
	}
`;

const PhoneNumberPanel = styled.div`
	&&& {
		padding-left: ${(props) => props.theme.spacings.medium};
		padding-right: ${(props) => props.theme.spacings.medium};

		@media ${(props) => props.theme.screens.medium} {
			padding-left: ${(props) => props.theme.spacings.extraLarge};
			padding-right: ${(props) => props.theme.spacings.extraLarge};
		}
	}
`;

const FullWidthContainer = styled.div`
	&&& {
		margin-left: -${(props) => props.theme.spacings.medium};
		margin-right: -${(props) => props.theme.spacings.medium};

		@media ${(props) => props.theme.screens.medium} {
			margin-left: -${(props) => props.theme.spacings.extraLarge};
			margin-right: -${(props) => props.theme.spacings.extraLarge};
		}
	}
`;

const PhoneNumberPanelContainer = styled.div`
	&&& {
		background-color: ${(props) => props.theme.colors.text200};
	}
`;

const HeaderImageWrapper = styled.div`
	&&& {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		@media ${(props) => props.theme.screens.medium} {
			position: absolute;
			top: 0;
			right: 0;
			width: 300px;
		}
	}
`;

const HeaderImageSvgContainer = styled.div`
	&&& {
		width: calc(100% + ${(props) => props.theme.spacings.extraLarge});
		aspect-ratio: 1.6;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: -${(props) => props.theme.spacings.medium} -${(props) => props.theme.spacings.medium} 0 -${(props) => props.theme.spacings.medium};

		svg {
			width: 100%;
			height: auto;
		}

		@media ${(props) => props.theme.screens.medium} {
			height: 240px;
			aspect-ratio: 1.25;
			margin: 0;
			border-top-right-radius: ${(props) => props.theme.radii.default};
			border-bottom-right-radius: ${(props) => props.theme.radii.default};
		}
	}
`;

const HeaderImage = styled.div<HeaderImageProps>`
	&&& {
		width: calc(100% + ${(props) => props.theme.spacings.extraLarge});
		aspect-ratio: 1.6;
		overflow: hidden;
		background-image: url('${(props) => props.$backgroundImage}');
		background-size: cover;
		background-repeat: no-repeat;
		background-origin: content-box;
		background-position: center;
		display: block;

		margin: -${(props) => props.theme.spacings.medium} -${(props) => props.theme.spacings.medium} 0 -${(props) => props.theme.spacings.medium};

		@media ${(props) => props.theme.screens.medium} {
			height: 240px;
			aspect-ratio: 1.25;
			margin: 0;
			border-top-right-radius: ${(props) => props.theme.radii.default};
			border-bottom-right-radius: ${(props) => props.theme.radii.default};
		}
	}
`;

const AdviceCharacteristicBlock = styled.div`
	padding: ${(props) => props.theme.spacings.medium};
	position: relative;
	color: ${(props) => props.theme.colors.text700};

	@media ${(props) => props.theme.screens.medium} {
		padding: 0;

		&:first-child {
			padding-top: ${(props) => props.theme.spacings.extraLarge};
		}
	}

	& > div {
		display: flex;
		flex-direction: column;
		gap: ${(props) => props.theme.spacings.medium};

		& > p:not(:last-child) {
			margin: 0 0 0 -${(props) => props.theme.spacings.medium};
			padding: 0 ${(props) => props.theme.spacings.large} 0 ${(props) => props.theme.spacings.medium};

			@media ${(props) => props.theme.screens.medium} {
				padding: 0 ${(props) => props.theme.spacings.medium} 0 0;
				margin: 0;
			}
		}

		& > ul {
			margin-right: ${(props) => props.theme.spacings.extraLarge};
		}
	}

	& p:last-child {
		margin-bottom: 0;
	}

	*:first-child {
		padding-top: 0 !important;
	}
	*:last-child {
		padding-bottom: 0 !important;
	}

	& .collapsible {
		margin: 0;
		margin-left: -${(props) => props.theme.spacings.medium};
		margin-right: -${(props) => props.theme.spacings.medium};
		padding: ${(props) => `${props.theme.spacings.medium} 0 0 ${props.theme.spacings.medium}`};
		color: ${(props) => props.theme.colors.text700};
		border-top: solid 4px ${(props) => props.theme.colors.accent100};

		@media ${(props) => props.theme.screens.medium} {
			margin-left: 0;
			padding-left: 0 !important;
			padding-bottom: 0 !important;
		}

		ul {
			list-style-type: disc;
			padding-inline-start: 40px;
		}
		li {
			list-style-type: disc;
		}

		& .collapsible-header {
			padding-left: 0;

			& .expandableTextButton {
				padding-top: ${(props) => props.theme.spacings.small};
			}
		}

		& + p {
			border-top: solid 4px ${(props) => props.theme.colors.accent100};
			padding-top: ${(props) => props.theme.spacings.medium} !important;
		}
	}
`;

const AdviceLink = styled.a`
	color: ${(props) => props.theme.colors.accent};
	text-decoration: underline;
	display: flex;
	flex-direction: row;
	gap: ${(props) => props.theme.spacings.small};
	align-items: center;

	&:hover {
		color: ${(props) => props.theme.colors.accent700};
	}
`;

const ParacetamolAdviceBlock = styled.div`
	& .collapsible-header {
		padding-left: 0;
	}
`;

const ChipsWrapper = styled(SA.ChipsWrapper)`
	position: sticky;
	top: 0;
	background: #fff;
	z-index: 1;
	padding: ${(props) => props.theme.spacings.medium};
`;

const AdviceOutro = styled.div`
	color: ${(props) => props.theme.colors.text700};
	padding: ${(props) => props.theme.spacings.medium};

	@media ${(props) => props.theme.screens.medium} {
		padding-left: 0;
	}
`;

export default {
	ActivityActionsContactButtons,
	ActivityBubble,
	ActivityBubbleContactRequestProcedure,
	ActivityGroup,
	Advice,
	AdviceCharacteristicBlock,
	AdviceContent,
	AdviceHeader,
	AdviceHeaderButton,
	AdviceHeaderIcon,
	AdviceHeaderTitle,
	AdviceIntro,
	AdviceLink,
	AdviceOutro,
	AdviceRestart,
	AdviceTitle,
	BubbleHeaderComplaint,
	CallbackRequestButton,
	ChipsWrapper,
	FullWidthContainer,
	GeneralAdvice,
	GeneralAdviceAdvice,
	GeneralAdviceAdviceContent,
	Nav,
	NavItem,
	ParacetamolAdviceBlock,
	PhoneNumberPanel,
	PhoneNumberPanelContainer,
	PhoneNumberPanelLabel,
	HeaderImage,
	HeaderImageWrapper,
	HeaderImageSvgContainer,
	ShowPhoneNumbersButton,
	TableOfContents
};
