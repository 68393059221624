import { getLabel } from './getLabels';
import type { ActivityStep, Settings } from '../../models';

export const initInitialConversation = (settings: Settings): ActivityStep[] => {
	let step = 0;
	const conversation: ActivityStep[] = [];

	/**
	 *
	 * @param {ActivityStep} step step to add
	 */
	const addConversationStep = (stepToAdd: ActivityStep) => {
		conversation.push({
			...stepToAdd,
			step: (step += 1)
		});
	};

	const addInitialGenderAgeSteps = (order: ('gender' | 'age')[]) => {
		order.forEach((step) => {
			step === 'age'
				? addConversationStep({
					step: 0,
					id: 'initialAgeSelector',
					type: 'ageSelector',
					title: getLabel('QuestionWhatAge', labels),
					info: getLabel('QuestionWhatAgeInfo', labels, false, true),
					profile: 'age'
				})
				: addConversationStep({
					step: 0,
					id: 'initialGenderSelector',
					type: 'genderSelector',
					title: getLabel('QuestionWhatGender', labels),
					info: getLabel('QuestionWhatGenderInfo', labels, false, true),
					profile: 'gender'
				});
		})
	}

	/**
	 * Setup steps
	 */

	const labels = settings.applicationTexts;
	const prefill = settings.prefill;
	const initialQuestionsOrder: ('age' | 'gender')[] = settings.startQuestionType === 'age'
		? ['age', 'gender']
		: ['gender', 'age'];
	if (prefill && prefill.active && prefill.bodypart && prefill.complaint) {
		addConversationStep({
			step: 0,
			id: 'initialDirectTriage',
			type: 'directTriage',
			questions: []
		});
	} else if (settings.startWithTriageSearch) {
		addConversationStep({
			step: 0,
			id: 'initialSearchTriage',
			type: 'searchTriage',
			title: getLabel('QuestionWhatSearchTriage', labels),
			profile: 'selectedSearchTriageHit'
		});
	} else if (settings.startWithAbcdTriage) {
		addInitialGenderAgeSteps(initialQuestionsOrder);
	} else {
		addInitialGenderAgeSteps(initialQuestionsOrder);

		addConversationStep({
			step: 0,
			id: 'initialBodyAreaSelector',
			type: 'bodyAreaSelector',
			title: getLabel('QuestionWhatBodyPart', labels),
			info: getLabel('QuestionWhatBodyPartInfo', labels, false, true),
			profile: 'bodypart'
		});
	}

	return conversation;
};
