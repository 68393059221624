import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';

type Align = 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';

interface LogoElementProps {
	$align: Align;
	$size: number;
}

const LogoElement = styled.div<LogoElementProps>`
	display: block;
	text-align: ${(props) => props.$align};
	margin-bottom: ${(props) => (props.$align === 'end' ? '-10px' : '0px')};
	@media ${(props) => props.theme.screens.medium} {
		margin-bottom: ${(props) => (props.$align === 'end' ? '-20px' : '0px')};
	}
	svg {
		width: ${(props) => `${props.$size}px`};
		/* vertical-align:top; */
		path {
			/* fill: ${(props) => props.theme.colors.accent}; */
		}
	}
`;

const Link = styled.a`
	&&& {
		background-color: transparent;
		margin: 0;
		border: 0;
		padding: 0;

		:focus-visible {
			outline: 2px auto ${(props) => props.theme.colors.focus};
		}
	}
`;

interface LogoProps {
	align?: Align;
	size?: number;
}

const Logo: FunctionComponent<LogoProps> = (props) => {
	const [{ settings }] = useStateContext();
	const [linkTitle, setLinkTitle] = useState('');

	useEffect(() => {
		const tmpLinkTitle = getLabel('WidgetLogoLinkLabel', settings.applicationTexts, true);
		setLinkTitle(tmpLinkTitle);
	}, [settings.applicationTexts]);

	return (
		<LogoElement $align={props.align ?? 'start'} $size={props.size || 175} data-minddclass="minddlogo">
			<Link
				href="https://www.moetiknaardedokter.nl/?utm_source=widget&utm_medium=referral&utm_campaign=logo"
				target="_blank"
				rel="noopener noreferrer"
				title={linkTitle}
			>
				<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1203.51 74.09" role="img">
					<g>
						<path
							d="M111,79.65v-43c0-6.37-5.7-11.54-12.73-11.54a13.43,13.43,0,0,0-1.69.16v-.16H21c-7,0-12.73,5.17-12.73,11.54v43H111Z"
							transform="translate(-8.3 -7.33)"
							fill="#e30613"
						/>
						<path
							d="M44.42,9.75s-7.61,0-7.61,7.61V47.8s0,7.61,7.61,7.61H74.86s7.61,0,7.61-7.61V17.36s0-7.61-7.61-7.61Z"
							transform="translate(-8.3 -7.33)"
							fill="none"
							stroke="#e30613"
							strokeWidth="4.83"
						/>
						<polygon points="48.67 29.28 48.67 60.86 55.52 60.86 55.52 29.28 48.67 29.28 48.67 29.28" fill="#fff" />
						<polygon points="36.31 48.49 67.89 48.49 67.89 41.64 36.31 41.64 36.31 48.49 36.31 48.49" fill="#fff" />
						<g>
							<path
								d="M201.17,79.65H187.74V67.32c0-5.09.07-9.74.29-13.73-1.55,3.84-4.21,9.82-6,13.51l-6.79,14.32L168.47,67.1c-1.77-3.69-4.43-9.67-6-13.51.22,4,.3,8.64.3,13.73V79.65H149.35V28h13.14l6.35,13.81c3,6.64,5.68,12.7,6.5,15.58a144.31,144.31,0,0,1,6.49-15.58L188.18,28h13Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M257.36,53.74c0,17.2-10,26.72-24,26.72s-23.84-9.37-23.84-26.57,10-26.73,24-26.73S257.36,36.54,257.36,53.74Zm-14.84.15c0-9.31-3.4-14.25-9.16-14.25s-9,4.8-9,14.1S227.75,68,233.51,68,242.52,63.19,242.52,53.89Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M305.79,79.65H265.7V28h39.57V40H279.88v6.86h15.28V58.76H279.88v8.86h25.91Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path d="M356.07,40.45H341.6v39.2H327.28V40.45H312.81V28h43.26Z" transform="translate(-8.3 -7.33)" fill="#e30613" />
							<path d="M403.76,79.65H389.15V28h14.61Z" transform="translate(-8.3 -7.33)" fill="#e30613" />
							<path
								d="M462.3,79.65h-17l-10.78-22-5.32,6.42V79.65H414.62V28h14.61V34c0,4.35-.07,9-.22,12.77,2.14-3.4,6.65-9.38,10-13.58L443.11,28h17.42L445,46.21Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M538.5,79.65H526.83L514.5,62.6c-2.28-3.18-6.71-9.82-8-12.11.37,2.29.44,9.15.44,13.36v15.8H493.17V28h12.62l11.37,15.29c2.36,3.17,6.94,10,8.05,12.18-.37-2.29-.44-9.16-.44-13.37V28H538.5Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M597.85,79.65h-15.5l-2.8-9.08H564l-2.8,9.08H545.88L564.63,28H579.1ZM576.08,59.2l-1-3.39c-1.85-6.65-2.66-9.45-3.32-12.92-.67,3.47-1.48,6.35-3.33,12.92l-1,3.39Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M654.11,79.65h-15.5l-2.81-9.08H620.3l-2.8,9.08H602.14L620.89,28h14.47ZM632.33,59.2l-1-3.39c-1.84-6.65-2.65-9.45-3.32-12.92-.66,3.47-1.47,6.35-3.32,12.92l-1,3.39Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M705.79,79.65h-16l-8.41-17.42H675.6V79.65H661.5V28h23.11c12.1,0,20.15,4.58,20.15,16.84,0,7.75-3.62,12.55-9.3,15.13ZM690.51,45.17c0-3.24-1.77-5.53-6.27-5.53H675.6V50.93H684C688.67,50.93,690.51,48.87,690.51,45.17Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M783.38,53.52c0,19.41-9.59,26.13-27.9,26.13H738.72V28h18.09C773.05,28,783.38,34.1,783.38,53.52Zm-14.69.22c0-9.52-3-13.51-12-13.51h-3.55V67.39h3.1C765.22,67.39,768.69,64,768.69,53.74Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path d="M832.11,79.65H792V28h39.56V40H806.2v6.86h15.28V58.76H806.2v8.86h25.91Z" transform="translate(-8.3 -7.33)" fill="#e30613" />
							<path
								d="M910.3,53.52c0,19.41-9.6,26.13-27.91,26.13H865.63V28h18.09C900,28,910.3,34.1,910.3,53.52Zm-14.7.22c0-9.52-3-13.51-12-13.51H880.1V67.39h3.1C892.13,67.39,895.6,64,895.6,53.74Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M964.63,53.74c0,17.2-10,26.72-24,26.72S916.8,71.09,916.8,53.89s10-26.73,24-26.73S964.63,36.54,964.63,53.74Zm-14.84.15c0-9.31-3.39-14.25-9.15-14.25s-9,4.8-9,14.1S935,68,940.79,68,949.79,63.19,949.79,53.89Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M1020.67,79.65h-17l-10.78-22-5.31,6.42V79.65H973V28H987.6V34c0,4.35-.08,9-.22,12.77,2.14-3.4,6.64-9.38,10-13.58l4.13-5.25h17.43l-15.58,18.24Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
						</g>
						<g>
							<path d="M1065.55,40.45h-14.46v39.2h-14.33V40.45H1022.3V28h43.25Z" transform="translate(-8.3 -7.33)" fill="#e30613" />
							<path
								d="M1114,79.65H1073.9V28h39.57V40h-25.39v6.86h15.28V58.76h-15.28v8.86H1114Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
							<path
								d="M1167.44,79.65h-16L1143,62.23h-5.75V79.65h-14.11V28h23.11c12.11,0,20.15,4.58,20.15,16.84,0,7.75-3.61,12.55-9.3,15.13Zm-15.28-34.48c0-3.24-1.78-5.53-6.28-5.53h-8.63V50.93h8.41C1150.31,50.93,1152.16,48.87,1152.16,45.17Z"
								transform="translate(-8.3 -7.33)"
								fill="#e30613"
							/>
						</g>
						<path
							d="M1211.81,41.34c0,5.9-2.81,9.08-9.16,12.18-4.2,2.06-5.83,4.13-5.83,7.67h-13.21c0-6.27,2.06-10.33,9.59-13.87,3.1-1.48,4.58-2.51,4.58-4.58S1196,39.2,1192,39.2c-5.24,0-10,2-14.24,4.87l-6.57-10.56a37.84,37.84,0,0,1,21-6.35C1204.28,27.16,1211.81,31.89,1211.81,41.34Zm-14,31.44a7.68,7.68,0,1,1-15.35,0,7.68,7.68,0,0,1,15.35,0Z"
							transform="translate(-8.3 -7.33)"
							fill="#e30613"
						/>
					</g>
				</svg>
			</Link>
		</LogoElement>
	);
};

export default Logo;
