import styled from 'styled-components';

import { default as SSQ } from '../SurveyQuestion/SurveyQuestion.styled';
import { default as ST } from '../ActivityTriage/ActivityTriage.styled';

const S = {
	Wrapper: styled.div`
		&:not(:first-child) {
			margin-top: ${(props) => props.theme.spacings.extraLarge};
		}
	`,
	Comment: styled(SSQ.Comment)`
		margin-top: ${(props) => props.theme.spacings.smallMedium};
		height: 100px;
		border-color: #cccccc;
		color: ${(props) => (props.disabled ? '#737373' : props.theme.colors.text900)}; // #737373 = placeholder color
		${(props) => (props.disabled ? 'background-color: white;' : '')}
	`,
	ConsultationPreparationAnswerButtons: styled(SSQ.SurveyAnswerButtons)``,
	Button: styled(SSQ.SurveyAnswerButton)`
		background-color: ${(props) => props.theme.colors.accent} !important;
		border: none !important;
		color: ${(props) => props.theme.colors.text100} !important;
		flex-grow: 1;
		text-align: center !important;
		border-radius: ${(props) => props.theme.radii.default} !important;

		&:hover {
			background-color: ${(props) => props.theme.colors.accent600} !important;
		}

		&:focus {
			outline: 5px auto -webkit-focus-ring-color !important;
		}

		@media ${(props) => props.theme.screens.medium} {
			flex-grow: 0;
		}
	`,
	EmpathyBubbleTitle: styled(ST.EmpathyBubbleTitle)``,
	ActivityBubble: styled(ST.ActivityBubble)``,
	ConfirmButtonWrapper: styled.div`
		margin-top: ${(props) => props.theme.spacings.medium};
		display: flex;
		flex-direction: column;
		border-radius: ${(props) => props.theme.radii.default} !important;
		@media ${(props) => props.theme.screens.medium} {
			flex-direction: row;
		}
	`
};

export default S;
