import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { MarkDownAnchorElement } from '../../helpers/support/renderMarkdown';

interface TermsOfUseElementProps {
	$modalOpen?: boolean;
}

const TermsOfUseElement = styled.div<TermsOfUseElementProps>`
	font-size: ${(props) => props.theme.fontSizes.sm};
	padding: ${(props) => props.theme.spacings.medium} 0;
	opacity: 0.78;
	text-align: start;

	@media ${(props) => props.theme.screens.medium} {
		padding: ${(props) => props.theme.spacings.medium} 0 0;
	}

	a {
		color: ${(props) => props.theme.colors.accent};

		:hover {
			text-decoration: none;
		}

		:focus-visible {
			outline: 2px solid ${(props) => props.theme.colors.focus};
		}
	}
`;

interface TermsOfUseProps {
	modalOpen?: boolean;
}

const TermsOfUse: FunctionComponent<TermsOfUseProps> = (props) => {
	const [{ settings }] = useStateContext();

	const termsOfUseUrl =
		settings.branding && settings.branding.termsOfUseUrl
			? settings.branding.termsOfUseUrl
			: getLabel('WidgetTermsOfUseUrl', settings.applicationTexts, true);

	const anchorProps = {
		href: termsOfUseUrl,
		target: '_blank',
		rel: 'noopener noreferrer'
	};

	return (
		<TermsOfUseElement $modalOpen={props.modalOpen} data-minddclass="termsofuse">
			<MarkDownAnchorElement {...anchorProps}>{getLabel('WidgetTermsOfUseButtonLabel', settings.applicationTexts, true)}</MarkDownAnchorElement>
		</TermsOfUseElement>
	);
};

export default TermsOfUse;
