import React, { useState, useCallback, useEffect, useRef, FunctionComponent } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';

import Button from '../Button';
import ActivityEndActions from '../ActivityEndActions/ActivityEndActions';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { CallSurveyPostInquiryForm } from '../../helpers/services';
import type { ActivityProps } from '../Activity';
import type { ActivityAnswerSurveyInquiry, SurveyQuestion } from '../../models';
import { InputCheckBox, InputNumber, InputText } from '../FormComponents/FormComponents';

interface SurveyInquiryProps extends ActivityProps {
	question: SurveyQuestion;
	answer?: ActivityAnswerSurveyInquiry;
}

const SurveyInquiry: FunctionComponent<SurveyInquiryProps> = (props) => {
	const [{ profile, settings, session }] = useStateContext();

	const [sendStatus, setSendStatus] = useState(false);
	const [isDisabled, setIsDisabled] = useState(props.disabled);
	const [isSending, setIsSending] = useState(false);
	const [validationStatus, setValidationStatus] = useState<boolean | null>(null);
	const [fields, setFields] = useState({
		SurveyEmail: '',
		SurveyTel: '',
		SurveyConsent: false
	});

	const { question } = props;

	const focusLastActivity = useCallback(
		(node: HTMLInputElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				setTimeout(() => {
					node.focus();
					node.select();
				}, 50);
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleChangeFormField = (field: React.ChangeEvent<HTMLInputElement>) => {
		if (field.target.type === 'checkbox') {
			setFields({
				...fields,
				[field.target.name]: field.target.checked ? true : null
			});
		} else {
			setFields({
				...fields,
				[field.target.name]: field.target.value === '' || field.target.value.trim() === '' ? null : field.target.value
			});
		}
	};

	const handleSendData = async () => {
		setIsDisabled(true);
		setIsSending(true);

		const valid = (
			session !== null
			&& (!question.askForEmailAddress || fields.SurveyEmail)
			&& (!question.askForPhoneNumber || isNumberOfChars(fields.SurveyTel) === 10)
			&& fields.SurveyConsent
		);

		if (valid) {
			setValidationStatus(true);

			const result = await CallSurveyPostInquiryForm(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				email: fields.SurveyEmail,
				phoneNumber: fields.SurveyTel,
				userAgreedToPrivacyStatement: fields.SurveyConsent
			});

			if (result) {
				setSendStatus(true);
				setIsSending(false);
				setIsDisabled(false);

				void props.handleActivityResponse({ ...props.answer, inquirySend: true });
			}
		} else {
			setValidationStatus(false);
			setIsSending(false);
			setIsDisabled(false);
		}
	};

	const handleActivityEndActions = () => {
		return props.handleActivityResponse({ ...props.answer, isFeedbackStarted: true });
	};

	const isNumberOfChars = (input?: string) => {
		if (!input) return 0;

		return input.replace(/( )/g, '').replace(/(\.)/g, '').length;
	};

	useEffect(() => {
		setFields({
			...fields,
			SurveyTel: profile.phoneNumber ?? ''
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SA.ActivityGroup>
			<SA.ActivityBubble>
				{!sendStatus && (
					<>
						{renderMarkdown(settings.survey.labels.formIntro)}
						<SP.Form>
							{question.askForEmailAddress && (
								<SP.Col>
									<InputText
										onChange={handleChangeFormField}
										name="SurveyEmail"
										$label={getLabel('WidgetFormLabelEmail', settings.applicationTexts, true)}
										defaultValue={fields.SurveyEmail}
									/>
								</SP.Col>
							)}
							{question.askForPhoneNumber && (
								<SP.Col>
									<InputNumber
										onChange={handleChangeFormField}
										name="SurveyTel"
										$label={getLabel('WidgetFormLabelTel', settings.applicationTexts, true)}
										defaultValue={fields.SurveyTel}
									/>
								</SP.Col>
							)}

							<SP.Row>
								<InputCheckBox
									onChange={handleChangeFormField}
									name="SurveyConsent"
									$label={renderMarkdown(settings.survey.labels.formConsent) || ''}
									type="checkbox"
								></InputCheckBox>
							</SP.Row>

							<SP.RowClean>
								{validationStatus === false && <SP.Error>{renderMarkdown(settings.survey.labels.formError)}</SP.Error>}
								<SA.ActivityActions>
									<Button onClick={() => void handleSendData()} disabled={isDisabled}>
										{isSending
											? getLabel('WidgetSendingButton', settings.applicationTexts, true)
											: getLabel('WidgetSendButton', settings.applicationTexts, true)}
									</Button>
								</SA.ActivityActions>
							</SP.RowClean>
						</SP.Form>
					</>
				)}
				{sendStatus && <SP.Succes>{renderMarkdown(settings.survey.labels.formSuccess)}</SP.Succes>}
			</SA.ActivityBubble>
			{sendStatus && (
				/* #2 follow up feedback/opnieuw */
				<SA.ActivityBubble>
					<ActivityEndActions
						showFeedbackAction={false}
						hideDownloadButton={settings.downloadConversationDisabled}
						handleActivityResponse={handleActivityEndActions}
					/>
				</SA.ActivityBubble>
			)}
		</SA.ActivityGroup>
	);
};

export default SurveyInquiry;
