import React, { FunctionComponent } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';

const MinddLogoLoader: FunctionComponent = () => {
	const [{ settings }] = useStateContext();

	return (
		<>
			<S.MinddLogoLoaderWrapper className="mind-logo-loader">
				<S.MinddLogo />
				<S.MiddLogoBorder />
				<svg viewBox="0 0 96 96">
					<circle cx="48" cy="48" r="42" />
				</svg>
			</S.MinddLogoLoaderWrapper>
			<SA.BubbleTitle className="text-centered full-width">{getLabel('LoadingAdvies', settings.applicationTexts)}</SA.BubbleTitle>
		</>
	);
};

export default MinddLogoLoader;
