import React, { FunctionComponent, useState, useCallback } from 'react';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import type { ActivityAnswer, ActivityAnswerConsultationPreparationQuestion, ActivityStepConsultationPreparation } from '../../models';

import S from './ConsultationPreparation.styled';
import { default as SA } from '../Activity/Activity.styled';
import ConsultationPreparationQuestion from '../ConsultationPreparationQuestion/ConsultationPreparationQuestion';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useDetermineNextStep } from '../../helpers/hooks/nextStep/useDetermineNextStep';
import { ActivityProps } from '../Activity';
import { getLabel } from '../../helpers/constants/getLabels';
import Preloader from '../Preloader/Preloader';
import { useTheme } from 'styled-components';

interface ConsultationPreparationProps extends ActivityProps {
	currentStep: ActivityStepConsultationPreparation;

	isLastActivity: boolean;
	modalOpen?: boolean;
	disabled: boolean;
	empathy?: string;
}

const ConsultationPreparation: FunctionComponent<ConsultationPreparationProps> = ({
	currentStep,
	isFirstActivity,
	isLastActivity,
	modalOpen,
	disabled,
	empathy,
	handleNext: baseHandleNext,
	setHandleNext,
	setDisableNext
}) => {
	const [
		{
			dialog: { confirm },
			settings,
			conversation
		},
		dispatch
	] = useStateContext();
	const determineNextStep = useDetermineNextStep();
	const [loading, setLoading] = useState(false);
	const themeContext = useTheme();

	const handleAnswerChange = (questionIndex: number) => (answer: ActivityAnswer) => {
		if (questionIndex === currentStep.questions.length - 1) {
			dispatch({
				type: 'conversation/setStepAnswer',
				stepId: currentStep.id,
				answer
			});
		} else {
			return handleAnswerSubmit(questionIndex, answer);
		}
		if (currentStep.questions[questionIndex]?.question.type === 'Normal' && answer !== undefined) {
			return handleAnswerSubmit(questionIndex, answer);
		} else {
			setDisableNext(!answer);
			setHandleNext(() => () => {
				void handleAnswerSubmit(questionIndex, answer);
			});
			return true;
		}
	};

	const handleAnswerSubmit = async (questionIndex: number, answer: ActivityAnswer) => {
		setDisableNext(true);
		const answerChanged = JSON.stringify(currentStep.questions[questionIndex].answer) !== JSON.stringify(answer);
		const wouldRemoveNextAnswer = !!currentStep.questions.find((q, i) => i > questionIndex && q.answer) && answerChanged;
		const shouldUpdateAnswer =
			!wouldRemoveNextAnswer ||
			(await confirm({
				header: getLabel('TriageChangePreviousQuestionAnswerHeader', settings.applicationTexts),
				message: getLabel('TriageChangePreviousQuestionAnswerMessage', settings.applicationTexts),
				cancelLabel: getLabel('QuestionDefaultNoLabel', settings.applicationTexts, true),
				confirmLabel: getLabel('QuestionDefaultYesLabel', settings.applicationTexts, true),
				buttonLayout: 'horizontal'
			}));

		if (shouldUpdateAnswer) {
			const newQuestions = currentStep.questions.slice(0, questionIndex + 1);
			newQuestions[newQuestions.length - 1].answer = answer as ActivityAnswerConsultationPreparationQuestion;
			dispatch({
				type: 'conversation/updateStep',
				step: {
					...currentStep,
					questions: newQuestions
				}
			});

			// If we change any answer, but the next section is already loaded, remove the next section
			if (answerChanged) {
				dispatch({
					type: 'updateConversationActivities',
					conversation: conversation.filter((step, index) => index <= conversation.findIndex((s) => s === currentStep))
				});
			}
			void handleNext(answer as ActivityAnswerConsultationPreparationQuestion, { ...currentStep, questions: newQuestions }, answerChanged, questionIndex);
		}

		return shouldUpdateAnswer;
	};

	const handleNext = useCallback(
		async (answer: ActivityAnswerConsultationPreparationQuestion, step: ActivityStepConsultationPreparation = currentStep, answerChanged = true, questionIndex = -1) => {
			const stepWithAnswer = {
				...step,
				answer: answer
			};
			setLoading(true);
			const nextStep = answerChanged ? await determineNextStep(stepWithAnswer) : currentStep;
			setLoading(false);

			if (nextStep) {
				if (nextStep.type !== 'consultationPreparation') {
					dispatch({
						type: 'conversation/setStepAnswer',
						stepId: currentStep.id,
						answer
					});
					setDisableNext(false);
					dispatch({
						type: 'conversation/setNextStep',
						currentStepId: currentStep.id,
						step: nextStep
					});

					// Automatically move on to the next section if this was the last question
					// But only if the last question was not a 'Normal' question
					// (prevent double 'Volgende')
					if (currentStep.questions[currentStep.questions.length - 1].question.type !== 'Normal') {
						baseHandleNext();
					}
				} else {
					dispatch({
						type: 'conversation/updateStep',
						step: nextStep
					});
					if (!answerChanged && questionIndex >= currentStep.questions.length - 1 && answer !== undefined) {
						baseHandleNext();
					}
				}
			}
		},
		[dispatch, determineNextStep, currentStep.questions.length]
	);

	return (
		<SA.ActivityGroup>
			{/* Add margin to the bottom for smooth animation */}
			<SA.ActivityBubble>
				{empathy && <S.EmpathyBubbleTitle>{renderMarkdown(empathy)}</S.EmpathyBubbleTitle>}

				{currentStep.questions.map((question, index) => (
					<ConsultationPreparationQuestion
						key={`${question.question.id}-${JSON.stringify(currentStep.questions[index - 1]?.answer)}`}
						disabled={disabled || loading}
						question={question}
						isFirstQuestion={index === 0}
						onChange={handleAnswerChange(index)}
						isFirstActivity={isFirstActivity}
						isLastActivity={isLastActivity}
						modalOpen={modalOpen}
						stepNumber={index + 1}
						isLastQuestion={index === currentStep.questions.length - 1}
						// New element is added before the 'next' button of the previous question is removed,
						// so add a scroll offset of 60 pixels to any question that does not have a 'next' button
						// to ensure the animation plays correctly.
						scrollOffset={currentStep.questions[index]?.question.type === 'Normal' ? 60 : 0}
					/>
				))}

				{loading ? (
					<Preloader style={{ marginTop: themeContext.spacings.large }} />
				) : (
					<div style={{ height: `calc(${themeContext.spacings.large} + ${themeContext.spacings.small})` }} />
				)}
			</SA.ActivityBubble>
		</SA.ActivityGroup>
	);
};

export default ConsultationPreparation;
