import React, { useEffect, useRef, useState, FunctionComponent } from 'react';

import S from './InfoButton.styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { useTheme } from 'styled-components';

const INFO_POPOVER_TAIL_SIZE = 6;
const INFO_POPOVER_SCREEN_INSET = 10;

const InfoButton: FunctionComponent<React.PropsWithChildren> = (props) => {
	const [{ settings }] = useStateContext();
	const [infoVisible, setInfoVisible] = useState(false);
	const themeContext = useTheme();
	const infoContentRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const infoTailRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const anchorRef = useRef<HTMLButtonElement>() as React.RefObject<HTMLButtonElement>;

	useEffect(() => {
		if (infoContentRef.current && infoTailRef.current) {
			if (infoVisible) {
				const anchorPosition = anchorRef.current?.getBoundingClientRect() ?? { x: -1000, y: -1000 };
				infoTailRef.current.style.display = `block`;

				let newTop = anchorPosition.y - infoContentRef.current.clientHeight - INFO_POPOVER_TAIL_SIZE;
				let flipTail = false;
				// Ensure infobox does not overlap with header,
				// as the header will render overtop of the infobox
				if (newTop < INFO_POPOVER_SCREEN_INSET) {
					// Move element to bottom instead
					newTop += infoContentRef.current.clientHeight + 40;
					flipTail = true;
				}

				const bottom = newTop + infoContentRef.current.clientHeight;
				if (bottom > window.innerHeight) {
					newTop -= bottom - window.innerHeight + INFO_POPOVER_SCREEN_INSET;
				}

				// Set tail's direction
				if (flipTail) {
					infoTailRef.current.style.borderTop = 'none';
					infoTailRef.current.style.borderBottom = `10px solid #fff`;
				} else {
					infoTailRef.current.style.borderBottom = 'none';
					infoTailRef.current.style.borderTop = `10px solid #fff`;
				}

				infoTailRef.current.style.top = `${flipTail ? newTop - INFO_POPOVER_SCREEN_INSET : newTop + infoContentRef.current.clientHeight}px`;
				infoContentRef.current.style.top = `${newTop}px`;

				// Try to shift info content left if it's off-screen
				let left = anchorPosition.x + 2 - infoContentRef.current.scrollWidth / 2;
				const rightEdge = anchorPosition.x + 2 + infoContentRef.current.scrollWidth / 2;
				if (rightEdge > window.outerWidth - INFO_POPOVER_SCREEN_INSET) {
					left -= rightEdge - (window.outerWidth - INFO_POPOVER_SCREEN_INSET);
				}
				// But don't push it off the left side of the screen
				if (left < INFO_POPOVER_SCREEN_INSET) {
					left = INFO_POPOVER_SCREEN_INSET;
				}

				infoContentRef.current.style.left = `${left}px`;
				infoTailRef.current.style.left = `${anchorPosition.x + 2}px`;
			} else {
				infoContentRef.current.style.top = `1000vh`;
				infoTailRef.current.style.display = `none`;
			}
		}
	}, [infoVisible]);

	return (
		<S.InfoButtonWrapper>
			<S.Info>
				<S.InfoContentBox ref={infoContentRef}>
					<S.InfoContent>{props.children}</S.InfoContent>
					<S.InfoClose data-testid="info-close" onClick={() => setInfoVisible(false)}>
						<svg style={{ width: 18, height: 18 }} viewBox="5 5 19 19">
							<path
								fill={themeContext.colors.accent600}
								d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
							/>
						</svg>
					</S.InfoClose>
				</S.InfoContentBox>
				<S.InfoTail ref={infoTailRef} />
			</S.Info>
			<S.InfoButton
				ref={anchorRef}
				$pressed={infoVisible}
				title={getLabel('QuestionMoreInformationButtonTitle', settings.applicationTexts, true)}
				onClick={() => setInfoVisible(!infoVisible)}
				data-testid="info-button"
				tabIndex={0}
			>
				?
			</S.InfoButton>
		</S.InfoButtonWrapper>
	);
};

export default InfoButton;
