import React, { useState, useCallback, FunctionComponent } from 'react';
import { compiler } from 'markdown-to-jsx';

import { default as SA } from '../Activity/Activity.styled';
import S from './SurveyQuestion.styled';
import type { ActivityProps } from '../Activity';
import type { ActivityAnswerSurveyQuestion, SurveyQuestion as Question } from '../../models';

interface SurveyQuestionProps extends ActivityProps {
	question: Question;
	answer?: ActivityAnswerSurveyQuestion;
}

const SurveyQuestion: FunctionComponent<SurveyQuestionProps> = (props) => {
	const [answerText, setAnswerText] = useState<string>();

	const { question } = props;
	const answers = question.answers;

	const focusLastActivity = useCallback(
		(node: HTMLHeadingElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleSetComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setAnswerText(e.target.value);
	};

	const handleActivityResponse = async (answerId: number) => {
		await props.handleActivityResponse({ answerId: answerId, answerText: answerText });
	};

	return (
		<SA.ActivityBubble>
			<SA.BubbleTitle ref={focusLastActivity} tabIndex={-1}>
				{compiler(question.title, {
					overrides: {
						p: {
							component: ({ children, ...props }) => <p {...props}>{children}</p>,
							props: {
								'data-texttype': 'markdown'
							}
						},
						img: {
							component: ({ children, ...props }) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
						}
					}
				})}
			</SA.BubbleTitle>
			{question.type === 'Open' && ( // Open
				<S.Comment onChange={handleSetComment} disabled={props.disabled}></S.Comment>
			)}
			{(question.type === 'Normal' || question.type === 'Open') && ( // Normal or Open
				<S.SurveyAnswerButtons>
					{answers.map((answer) => (
						<S.SurveyAnswerButton
							key={answer.id}
							disabled={props.disabled}
							$state={props.answer?.answerId === answer.id}
							onClick={() => void handleActivityResponse(answer.id)}
						>
							{answer.title}
						</S.SurveyAnswerButton>
					))}
				</S.SurveyAnswerButtons>
			)}
		</SA.ActivityBubble>
	);
};

export default SurveyQuestion;
