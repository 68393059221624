import React from 'react';
import SB from './Buttons.styled';

interface RadioButtonProps {
	label: string;
	name: string;
	value: string;
	checked: boolean;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

interface RadioButtonGroupProps {
	children: React.ReactNode;
}

export const RadioButtonGroup: React.FunctionComponent<RadioButtonGroupProps> = ({ children }) => {
	return <SB.RadioGroupWrapper className="RadioGroupWrapper">{children}</SB.RadioGroupWrapper>;
};

export const RadioButton = ({ label, name, value, checked, onChange, onClick }: RadioButtonProps) => {
	return (
		<SB.RadioWrapper checked={checked} className="RadioWrapper">
			<label>
				<span className="labeltext">{label}</span>
				<input type="radio" name={name} value={value} checked={checked} onChange={onChange} onClick={onClick} />
				<span className="checkmark"></span>
			</label>
		</SB.RadioWrapper>
	);
};
