import React from 'react';
import styled from 'styled-components';
import type { ButtonProps, DivProps } from '../../models/widget/Styled';
import { default as SA } from '../Activity/Activity.styled';

const HEADER_HEIGHT = 88;
const SECTION_HEADER_HEIGHT = 41;
const FULL_HEADER_HEIGHT = HEADER_HEIGHT + SECTION_HEADER_HEIGHT;

interface WidgetProps {
	$modalOpen?: boolean;
	$mode?: string;
	$hasBranding?: boolean;
	$hasHeader?: boolean;
	$small?: boolean;
}

interface MainHeaderProps {
	$scrollbarWidth: number;
}

// When the padding-top changes here, in Conversation/index.js, the verticalOffSet also must be changed
const Widget = styled.div.attrs((props) => ({ dir: props.theme.isRtl ? 'rtl' : 'ltr' }))<WidgetProps>`
	&&& {
		color: #000;
		border: 0;
		width: 100%;
		background-color: ${(props) => (props.$modalOpen ? '#fff' : 'transparent')};
		scroll-behavior: smooth;
		line-height: 1.5;

		//170px is the fixed header size. This needs an other calculation when its not in Module mode.
		${(props) => props.$modalOpen && 'overflow-y: auto;'}
		${(props) =>
			props.$modalOpen && props.$hasHeader
				? `height: calc(100% - ${FULL_HEADER_HEIGHT}px); margin-top: calc(${FULL_HEADER_HEIGHT}px);`
				: 'height: 100%; margin-top: 0px;'}
		
		display: flex;
		flex-direction: row;
		justify-content: center;

		// Provides some horizontal padding for desktop when the widget is not placed in a small container.
		padding-left: ${(props) => (props.$small ? '0' : props.theme.spacings.large)};
		padding-right: ${(props) => (props.$small ? '0' : props.theme.spacings.large)};
	}
`;

const WidgetHeader = styled(SA.VisuallyHidden).attrs({ as: 'h1' })``;

const Header = styled.div`
	position: absolute;
	top: ${-FULL_HEADER_HEIGHT}px;
	z-index: 1000;
	background: ${(props) => props.theme.colors.accent100};
	width: 100%;
	margin: auto;
	box-shadow: inset 0px -8px 16px -16px rgba(0, 0, 0, 0.2);
	padding: ${(props) => props.theme.spacings.medium} 0;
	@media ${(props) => props.theme.screens.medium} {
		padding-left: ${(props) => props.theme.spacings.large};
		padding-right: ${(props) => props.theme.spacings.large};
		border-top-left-radius: ${(props) => props.theme.radii.default};
		border-top-right-radius: ${(props) => props.theme.radii.default};
	}
`;

const MainHeader = styled.div<MainHeaderProps>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	@media ${(props) => props.theme.screens.medium} {
		&:after {
			width: ${(props) => props.$scrollbarWidth}px;
			height: 100%;
			content: '';
		}
		padding-top: ${(props) => props.theme.radii.default};
	}
`;

const MainHeaderInner = styled.div`
	width: 100%;
	max-width: min(${(props) => props.theme.sizes.maxWidth}px, 100%);
	display: flex;
	flex-direction: row-reverse;
	position: relative;
	padding: 0 ${(props) => props.theme.spacings.medium};

	@media ${(props) => props.theme.screens.medium} {
		padding: 0 ${(props) => props.theme.spacings.smallMedium};
	}
`;

const SectionHeaderWrapper = styled.div`
	position: absolute;
	top: ${-FULL_HEADER_HEIGHT + HEADER_HEIGHT}px;
	z-index: 1000;
	width: 100%;
	margin: auto;
	background-color: #fff;
	box-shadow: inset 0px -8px 16px -16px rgba(0, 0, 0, 0.2);
	border-bottom: ${(props) => props.theme.colors.text400} 1px solid;
`;

const SectionHeader = styled.div<MainHeaderProps>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 40px;
	width: 100%;
	align-items: center;

	@media ${(props) => props.theme.screens.medium} {
		&:after {
			width: ${(props) => props.$scrollbarWidth}px;
			height: 100%;
			content: '';
		}
	}
`;

const SectionHeaderInner = styled.div`
	width: 100%;
	max-width: calc(${(props) => props.theme.sizes.maxWidth}px + ${(props) => props.theme.spacings.extraExtraExtraLarge});
	display: flex;
	flex-direction: column;
	padding: 0 ${(props) => props.theme.spacings.small};

	@media ${(props) => props.theme.screens.medium} {
		padding: 0 ${(props) => props.theme.spacings.extraExtraLarge};
	}
`;

const ModalCloseButton = styled.button`
	&&& {
		display: flex;
		margin-left: ${(props) => props.theme.spacings.medium};
		align-self: center;
		width: 28px;
		height: 28px;
		z-index: 1050;
		background: transparent;
		padding: 0;
		border: none;
		right: ${(props) => props.theme.spacings.small};
		top: ${(props) => props.theme.spacings.medium};
		cursor: pointer;
		border-radius: ${(props) => props.theme.radii.default};
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;

		&:focus,
		&:hover {
			svg path {
				fill: ${(props) => props.theme.colors.accent};
			}
		}

		&:focus-visible {
			outline: 2px solid ${(props) => props.theme.colors.focus};
		}

		svg path {
			fill: ${(props) => props.theme.colors.accent};
		}
		svg {
			width: 28px;
			height: 28px;
		}

		@media ${(props) => props.theme.screens.medium} {
			right: 0;
		}
	}
`;

const HeaderTitleWrapper = styled.div`
	display: flex;
	margin-top: ${(props) => props.theme.spacings.medium};
	font-size: ${(props) => props.theme.fontSizes.base};
	height: 40px;
	flex-direction: row;
`;

const HeaderPractice = styled.div`
	padding: ${(props) => props.theme.spacings.small};
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	border-radius: ${(props) => props.theme.radii.default};
	background-color: ${(props) => props.theme.colors.text300};
	height: 56px;
`;

const SectionTitle = styled.div`
	font-size: ${(props) => props.theme.fontSizes.base};
	font-weight: bold;
	padding-left: ${(props) => props.theme.spacings.small};
	@media ${(props) => props.theme.screens.medium} {
		padding-left: 0;
		padding-right: 0;
	}
`;

const HeaderInnerLogo = styled.div`
	height: 40px;
	border-radius: ${(props) => props.theme.radii.default};
	background-color: #fff;
	position: relative;
	justify-content: center;
	align-items: center;

	& img {
		height: 100%;
	}
`;

const PracticeInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: ${(props) => props.theme.spacings.extraSmall};
	padding-left: ${(props) => props.theme.spacings.small};
	color: ${(props) => props.theme.colors.text600};
	line-height: 1.3;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const PracticeName = styled.div`
	font-size: ${(props) => props.theme.fontSizes.base};
	font-weight: bold;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const PracticeTimes = styled.div`
	display: flex;
	font-size: ${(props) => props.theme.fontSizes.sm};
`;

const Body = styled.div`
	&&& {
		height: 100%;
		width: 100%;
		padding-top: 16px;
		max-width: ${(props) => `${props.theme.sizes.maxWidth}px`};
		color: ${(props) => props.theme.colors.accent};
		padding: ${(props) => props.theme.spacings.medium} ${(props) => props.theme.spacings.medium} 0;

		@media ${(props) => props.theme.screens.medium} {
			padding-top: ${(props) => props.theme.spacings.large};
		}
	}
`;

export default {
	Widget: React.forwardRef((props: DivProps<WidgetProps>, ref) => (
		// @ts-expect-error prop types are correct
		<Widget {...props} ref={ref} data-minddclass="widget">
			{props.children}
		</Widget>
	)),
	WidgetHeader,
	Header,
	MainHeader,
	MainHeaderInner,
	SectionHeaderWrapper,
	SectionHeader,
	ModalCloseButton: (props: ButtonProps) => (
		<ModalCloseButton {...props} data-minddclass="modalclosebutton">
			{props.children}
		</ModalCloseButton>
	),
	SectionHeaderInner,
	HeaderTitleWrapper,
	HeaderPractice,
	SectionTitle,
	HeaderInnerLogo: (props: DivProps) => (
		<HeaderInnerLogo {...props} data-minddclass="headerinnerlogo">
			{props.children}
		</HeaderInnerLogo>
	),
	PracticeInfo,
	PracticeName,
	PracticeTimes,
	Body: (props: DivProps) => (
		<Body {...props} data-minddclass="widgetbody">
			{props.children}
		</Body>
	)
};
