import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useMemo, useState } from 'react';
import PhoneInput, { Country, ExternalValue, getCountryCallingCode, isValidPhoneNumber, parsePhoneNumber, Value } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import getSelectStyles from '../../helpers/styled/Select';
import SP from '../Procedure/Procedure.styled';
import FieldLabel from './FieldLabel';
import S from './FormComponents.styled';
import PhoneInputLabels from './PhoneInputLabels';

const flagImages = require.context('../../assets/phone-country-flag', false, /\.svg$/);

const MINDD_LANG_TO_COUNTRY: {
	[key: string]: Country;
} = {
	nl: 'NL', // Dutch -> Netherlands
	be: 'BE', // Belgian Dutch -> Belgium
	en: 'GB', // English -> United Kingdom
	de: 'DE', // German -> Germany
	ar: 'MA', // Arabic -> Morocco
	fr: 'FR', // French -> France
	pl: 'PL', // Polish -> Poland
	pap: 'AW', // Papiamento -> Aruba
	'pap-cw': 'CW', // Papiamento -> Curaçao
	ru: 'RU', // Russian -> Russia
	tr: 'TR', // Turkish -> Turkey
	uk: 'UA' // Ukrainian -> Ukraine
};

const PHONE_EU_COUNTRIES: Readonly<Country[]> = Object.freeze([
	'NL',
	'BE',
	'BG',
	'CY',
	'DK',
	'DE',
	'EE',
	'FI',
	'FR',
	'GR',
	'HU',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'AT',
	'PL',
	'PT',
	'RO',
	'HR',
	'SK',
	'SI',
	'ES',
	'CZ',
	'SE',
	'GF',
	'GP',
	'IS',
	'LI',
	'MQ',
	'YT',
	'SX',
	'NO',
	'RE'
] as const);

const PHONE_NL_COUNTRY: Readonly<Country[]> = Object.freeze(['NL'] as const);

const REGION_TO_COUNTRIES: { [key: string]: Readonly<Country[]> } = {
	Europe: PHONE_EU_COUNTRIES,
	Netherlands: PHONE_NL_COUNTRY
};

interface OptionCountry {
	value: Country;
	label: string;
}

interface SelectProps<T = { value: string; label: string }> {
	name?: string;
	value?: T;
	onChange: (value?: T) => void;
	onFocus: () => void;
	onBlur: () => void;
	options: { label: string; value: T }[];
	iconComponent: React.ElementType;
	disabled?: boolean;
	readOnly?: boolean;
	tabIndex?: number;
	className: string;
}

type InputPhoneProps = {
	id: string;
	name: string;
	defaultValue?: string;
	$label: string | JSX.Element;
	$valid?: boolean | null;
	$invalidError?: string;
	disabled?: boolean;
	autoComplete?: string;
	onChange: (value?: string) => void;
	required?: boolean;
};

const InputPhone: FunctionComponent<InputPhoneProps> = (props) => {
	const isValid = props.$valid;
	const [inputPhoneFocused, setInputPhoneFocused] = useState(false);
	const [{ settings }] = useStateContext();
	const showError = props.$valid === false && !!props.$invalidError;
	const [value, setValue] = useState<Value | ExternalValue | undefined>(props.defaultValue);
	const themeContext = useTheme();

	const selectStyles = useMemo(
		() =>
			getSelectStyles<OptionCountry>(themeContext, {
				control: {
					width: 200,
					borderColor: isValid === false ? '#dc3545' : '#cccccc',
					borderWidth: isValid === false ? '2px' : '1px',
					':hover': {
						borderColor: isValid === false ? '#dc3545' : '#cccccc'
					}
				},

				input: { marginLeft: `calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio) + ${themeContext.spacings.small})` },
				singleValue: { overflowX: 'hidden' }
			}),
		[isValid, themeContext]
	);

	const countries = REGION_TO_COUNTRIES[settings.contactRequest.clientPhonenumberRegion] as Country[];

	const inputComponent = useMemo(() => {
		const inputPhoneInputComponent = React.forwardRef<HTMLInputElement>((icProps, ref) => (
			<SP.Field
				data-testid="tel"
				ref={ref}
				{...icProps}
				data-valid={props.$valid}
				name={props.name}
				style={{
					marginLeft: themeContext.spacings.extraSmall,
					borderColor: isValid === false ? '#dc3545' : '#cccccc',
					borderWidth: isValid === false ? '2px' : '1px'
				}}
			/>
		));
		inputPhoneInputComponent.displayName = 'inputPhoneInputComponent';
		return inputPhoneInputComponent;
	}, [isValid, props.name]);

	const selectComponent = useMemo(() => {
		const inputPhoneSelectComponent = (scProps: SelectProps<Country>) => (
			<Select<OptionCountry>
				{...scProps}
				value={scProps.options.find((option) => option.value === scProps.value)}
				onChange={(option) => scProps.onChange(option?.value ?? undefined)}
				isClearable={false}
				styles={selectStyles}
				formatOptionLabel={(option) => (
					<S.PhoneCountryOptionWrapper>
						<S.PhoneCountryOptionImageWrapper>
							<S.PhoneCountryOptionImage src={flagImages(`./${option.value}.svg`)} />
						</S.PhoneCountryOptionImageWrapper>
						<S.PhoneCountryOptionLabel>
							(+{getCountryCallingCode(option.value)}) {option.label}
						</S.PhoneCountryOptionLabel>
					</S.PhoneCountryOptionWrapper>
				)}
			/>
		);
		return inputPhoneSelectComponent;
	}, [selectStyles]);

	const handlePhoneInputOnChange = (value?: Value) => {
		setValue(value);

		if (value && isValidPhoneNumber(value)) {
			props.onChange(value);
		} else {
			props.onChange(undefined);
		}
	};

	const labels = PhoneInputLabels[settings.selectedLanguage.code.toLowerCase()] ?? PhoneInputLabels.en;

	const selectedPhoneCountryCode = MINDD_LANG_TO_COUNTRY[settings.selectedLanguage.code.toLowerCase()] ?? MINDD_LANG_TO_COUNTRY.nl;

	const defaultOrSelectedCountry = !countries || countries.includes(selectedPhoneCountryCode) ? selectedPhoneCountryCode : MINDD_LANG_TO_COUNTRY.nl;

	return (
		<S.PhoneInputWrapper>
			<FieldLabel htmlFor={props.id} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<PhoneInput
				id={props.id}
				value={value}
				onChange={handlePhoneInputOnChange}
				defaultCountry={defaultOrSelectedCountry}
				initialValueFormat="national"
				countrySelectComponent={selectComponent}
				inputComponent={inputComponent}
				autoComplete={props.autoComplete}
				disabled={props.disabled}
				countries={countries}
				addInternationalOption={false}
				labels={labels}
				focusInputOnCountrySelection={true}
				onFocus={() => setInputPhoneFocused(true)}
				onBlur={() => setInputPhoneFocused(false)}
				autoFocus={inputPhoneFocused}
			/>
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
					{props.$invalidError}
				</SP.Error>
			)}
		</S.PhoneInputWrapper>
	);
};

const parseInputPhoneNumber = (phoneNumber: string | null | undefined): string | undefined => {
	if (!phoneNumber) {
		return undefined;
	}

	const result = parsePhoneNumber(phoneNumber, { defaultCountry: MINDD_LANG_TO_COUNTRY.nl, extract: false });
	if (result && result.isValid()) {
		return result.number;
	}

	return undefined;
};

export default InputPhone;
export { parseInputPhoneNumber };
