import { useCallback } from 'react';
import { ActivityStep, ActivityStepConsultationPreparationQuestion, Advice } from '../../../models';
import { CallConsultationPreparationGetStartQuestion } from '../../services';
import { useStateContext } from '../useStateContext';
import { useNextStepConsultationPreparation } from './useNextStepConsultationPreparation';

export type UseNextStepCallbackRequestResultType = (advice: Advice) => Promise<ActivityStep>;

export const useNextStepCallbackRequest: () => UseNextStepCallbackRequestResultType = () => {
	const [{ session, settings }] = useStateContext();

	const handleNextStepConsultationPreparation = useNextStepConsultationPreparation();

	return useCallback(
		async (advice: Advice): Promise<ActivityStep> => {
			const result = await CallConsultationPreparationGetStartQuestion(settings.ApiKey, {
				languageCode: settings.selectedLanguage.code,
				sessionId: session.id,
				sessionToken: session.token
			});

			if (result && result.startQuestion) {
				const startQuestion: ActivityStepConsultationPreparationQuestion = {
					question: result.startQuestion,
					type: 'consultationPreparationQuestion'
				};

				return await handleNextStepConsultationPreparation(
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					advice.urgence,
					result.empathy,
					advice.showImageField,
					[startQuestion]
				);
			}

			return {
				id: 'requestCallback',
				type: 'requestCallback',
				urgence: advice.urgence,
				showImageField: advice.showImageField
			};
		},
		[session, settings.ApiKey, settings.selectedLanguage.code, handleNextStepConsultationPreparation]
	);
};
