import React, { FunctionComponent, PropsWithChildren, useState, useRef, useEffect, useCallback } from 'react';
import S from './ExpandableDiv.styled';
import SA from '../Activity/Activity.styled';
import { useTheme } from 'styled-components';

const useExpandable: (defaultExpanded?: boolean) => [boolean, () => void] = (defaultExpanded = false) => {
	const [expanded, setExpanded] = useState(defaultExpanded);

	const toggleExpanded = useCallback(() => {
		setExpanded(!expanded);
	}, [setExpanded, expanded]);

	return [expanded, toggleExpanded];
};

interface ExpandableDivProps extends PropsWithChildren {
	title?: string;
	defaultExpanded?: boolean;
	dark?: boolean;
	className?: string;
	id?: string;
	disabled?: boolean;
	noBorder?: boolean;
	hideToggleButton?: boolean;
	style?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
	setToggle?: React.Dispatch<() => void>;
}

const ExpandableDiv: FunctionComponent<ExpandableDivProps> = ({
	title,
	children,
	defaultExpanded = false,
	dark = false,
	className = '',
	id,
	disabled,
	noBorder,
	hideToggleButton,
	style,
	contentStyle,
	setToggle
}) => {
	const [expanded, toggleExpanded] = useExpandable(defaultExpanded);
	const expandableContentRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const themeContext = useTheme();

	useEffect(() => {
		if (expandableContentRef.current) {
			expandableContentRef.current.style.maxHeight = expanded ? `${expandableContentRef.current?.scrollHeight}px` : '0';
		}
	}, [expandableContentRef.current, expanded]);

	const handleClickToggle = () => {
		if (!disabled) {
			toggleExpanded();
		}
	};

	useEffect(() => {
		if (defaultExpanded !== expanded) {
			toggleExpanded();
		}
	}, [defaultExpanded]);

	useEffect(() => {
		setToggle?.(() => toggleExpanded);
	}, [setToggle, toggleExpanded]);

	return (
		<S.ExpandableTextBox $expanded={expanded} $dark={dark} className={`${className} ${noBorder ? 'no-border' : ''}`} id={id} style={style}>
			<S.ExpandableTextBoxHeader $disabled={disabled} onClick={handleClickToggle} className="collapsible-header">
				<SA.BubbleSubTitle>{title}</SA.BubbleSubTitle>
				{!disabled && !hideToggleButton && (
					<S.ExpandableTextBoxButton className="expandableTextButton">
						<svg width="8" height="5" viewBox="-5 -3 10 6" fill="none" xmlns="http://w3.org/2000/svg">
							<path d="M -3.5 -2.25 L -4 -1.75 L 0 2.25 L 4 -1.75 L 3.5 -2.25 L 0 1.25 L -3.5 -2.25" fill="#1C2122" />
						</svg>
					</S.ExpandableTextBoxButton>
				)}
			</S.ExpandableTextBoxHeader>
			<S.ExpandableTextBoxContent ref={expandableContentRef} className={expanded ? 'expanded' : ''} style={contentStyle}>
				<div style={{ marginTop: themeContext.spacings.medium }} />
				{children}
			</S.ExpandableTextBoxContent>
		</S.ExpandableTextBox>
	);
};

export default ExpandableDiv;
