import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SA from '../Activity/Activity.styled';
import SAA from '../ActivityAdvice/ActivityAdvice.styled';

const IconLabel = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: ${(props) => props.theme.spacings.medium};
`;

const IconBackground = styled.div`
	display: flex;
	width: 55px;
	border-radius: 50%;
	padding: 15px;
	background-color: #e5f7ed; // TODO successLight?
`;

const HeaderIcon = styled(FontAwesomeIcon)`
	width: 100%;
	height: 100%;
	color: #00b24a; // TODO errorLight?
`;

const AppointmentBullets = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacings.small};
`;

const AppointmentBullet = styled.div`
	line-height: 1.25;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${(props) => props.theme.spacings.small};

	& span {
		vertical-align: top;
		color: ${(props) => props.theme.colors.text700};
	}
`;

const AppointmentBulletIcon = styled(FontAwesomeIcon)`
	width: ${(props) => props.theme.spacings.smallMedium};
	height: ${(props) => props.theme.spacings.smallMedium};
	background: ${(props) => props.theme.colors.accent100};
	border-radius: 3px;
	padding: 6px;
`;

const Appointment = styled(SAA.ActivityBubble)`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacings.smallMedium};
	
	&&& p:last-child {
		margin-bottom: 0;
	}
`;

const S = {
	IconLabel,
	IconBackground,
	HeaderIcon,
	Appointment,
	AppointmentBullets,
	AppointmentBullet,
	AppointmentBulletIcon
};

export default S;
