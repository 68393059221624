import React, { FunctionComponent } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabelWithOverride } from '../../helpers/constants/getLabelWithOverride';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import { useTheme } from 'styled-components';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';

interface ActivityWelcomeProps {
	modalOpen?: boolean;
}

const ActivityWelcome: FunctionComponent<ActivityWelcomeProps> = (props) => {
	const [{ settings, modal }] = useStateContext();
	const TypeOfStyledActivity = props.modalOpen ? S.ClearActivityBubbleWhenOpen : S.ClearActivityBubble;
	const theme = useTheme();
	const isMobile = useWindowSize().width < theme.breakpoints.medium;
	const [, widgetIsSmall] = useWidgetIsNarrow();

	const welcomeText = getLabelWithOverride(settings.welcome_text, 'WidgetWelcomeText', settings.applicationTexts, settings.selectedLanguage.code);

	return (
		<TypeOfStyledActivity style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
			<SA.BubbleTitle>{welcomeText}</SA.BubbleTitle>
			{!modal.open && !isMobile && !widgetIsSmall && <TermsOfUse modalOpen={props.modalOpen} />}
		</TypeOfStyledActivity>
	);
};

export default ActivityWelcome;
