import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/minddlogo.png';
import type { DivProps } from '../../models/widget/Styled';

const MinddLogoLoaderWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;
	margin: 50px auto 20px auto;
	svg {
		animation: loader-circle-spin 4s linear infinite;
		color: #e61c29;
		fill: none;
		height: 100%;
		stroke: currentColor;
		stroke-dasharray: 263.4675292969;
		stroke-dashoffset: 263.4675292969;
		stroke-linecap: butt;
		stroke-linejoin: miter;
		stroke-width: 60;
		vertical-align: top;
		width: 100%;
		border-radius: 23%;
		z-index: 101;
		transform: rotate(-90deg);
	}

	@keyframes loader-circle-spin {
		100% {
			stroke-dashoffset: 0;
		}
	}
`;

const MinddLogo = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-image: url(${logo});
	background-size: contain;
	background-repeat: no-repeat;
	background-origin: content-box;
	padding: 4px;
	z-index: 102;
`;

const MiddLogoBorder = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: #f3b2b7;
	z-index: 100;
	border-radius: 23%;
`;

export default {
	MinddLogoLoaderWrapper: (props: DivProps) => (
		<MinddLogoLoaderWrapper {...props} data-minddclass="minddlogoloaderwrapper">
			{props.children}
		</MinddLogoLoaderWrapper>
	),
	MinddLogo: (props: DivProps) => (
		<MinddLogo {...props} data-minddclass="minddlogo">
			{props.children}
		</MinddLogo>
	),
	MiddLogoBorder: (props: DivProps) => (
		<MiddLogoBorder {...props} data-minddclass="minddlogoborder">
			{props.children}
		</MiddLogoBorder>
	)
};
