import React, { useState, useCallback, FunctionComponent, useEffect } from 'react';
import { compiler } from 'markdown-to-jsx';
import { useTheme } from 'styled-components';

import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import S from './ConsultationPreparationQuestion.styled';
import SIC from './InputChoices.styled';

import InputChoices from './InputChoices';
import InputNumber from './InputNumber';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import animateQuestion from '../../helpers/styled/AnimateQuestionSlide';
import type { ActivityAnswer, ActivityAnswerConsultationPreparationQuestion, ActivityStepConsultationPreparationQuestion } from '../../models';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { TextArea } from '../FormComponents/FormComponents';

interface ConsultationPreparationQuestionProps {
	question: ActivityStepConsultationPreparationQuestion;
	isFirstQuestion: boolean;
	disabled: boolean;
	onChange: (answer: ActivityAnswer) => boolean | Promise<boolean>;
	isFirstActivity: boolean;
	isLastActivity: boolean;
	modalOpen?: boolean;
	stepNumber: number;
	isLastQuestion: boolean;
	scrollOffset?: number;
}

const ConsultationPreparationQuestion: FunctionComponent<ConsultationPreparationQuestionProps> = (props) => {
	const [{ settings, refs }] = useStateContext();
	const themeContext = useTheme();

	const [answerId, setAnswerId] = useState<number | undefined>();
	const [answerText, setAnswerText] = useState<string>(props.question.answer?.answerText ?? '');
	const [answerChoices, setAnswerChoices] = useState<any[]>(props.question.answer?.answerChoices ?? []);
	const [sendStatus, setSendStatus] = useState(false);
	const [answerChanged, setAnswerChanged] = useState(false);

	const { question } = props;
	const answers = question.question.answers;
	const choices = question.question.choices
		? question.question.choices.map((c) => ({ id: c.id, title: c.title, selected: answerChoices.some((ac) => ac === c.id) }))
		: [];

	useEffect(() => {
		setAnswerChanged(false);
	}, [props.isLastQuestion, props.isLastActivity]);

	useEffect(() => {
		if (!props.question.answer) {
			setAnswerChanged(false);
			if (props.isLastQuestion) {
				setAnswerText('');
				setAnswerChoices([]);
				setAnswerId(undefined);
			}
		}
	}, [props.isLastQuestion, props.isLastActivity, props.question.answer]);

	const focusLastActivity = useCallback(
		(node: HTMLDivElement) => {
			if (node) {
				if (!props.isFirstQuestion && props.isLastActivity && !props.question.answer) {
					animateQuestion(node, themeContext, refs.widget?.current, props.scrollOffset ?? 0);
				}
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleAnswer = (answerId: number) => {
		setAnswerId(answerId);
	};

	const handleSetComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setAnswerChanged(true);
		setAnswerText(e.target.value);
	};

	const handleSetNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAnswerChanged(true);
		setAnswerText(e.target.value);
	};

	const handleChoiceChange = (choiceId: number, checked: boolean) => {
		setAnswerChanged(true);
		setAnswerChoices((prevAnswerChoices) => {
			if (checked) {
				// Only need to add if it's not already in the list
				if (!prevAnswerChoices.some((ac) => ac === choiceId)) {
					const newAnswerChoices = [...prevAnswerChoices, choiceId].sort((a, b) => a - b);
					return newAnswerChoices;
				}
			} else {
				const newAnswerChoices = prevAnswerChoices.filter((ac) => ac !== choiceId);
				if (newAnswerChoices.length !== prevAnswerChoices.length) {
					return newAnswerChoices;
				}
			}

			return prevAnswerChoices;
		});
	};

	const handleActivityResponse = (answerId: number) => {
		setSendStatus(true);

		void props.onChange({ answerId });
	};

	const handleSubmit = async (answer?: ActivityAnswerConsultationPreparationQuestion) => {
		if (await props.onChange(answer)) {
			setAnswerChanged(false);
		}
	};

	useEffect(() => {
		if (props.isLastQuestion) {
			if (answerChoices.length > 0 || answerText || answerId !== undefined) {
				void handleSubmit({
					answerId: answerId ?? answers[0].id,
					answerChoices,
					answerText
				});
			} else {
				if ((props.question.answer?.answerChoices?.length ?? 0) > 0 || props.question.answer?.answerText || props.question.answer?.answerId !== undefined) {
					void handleSubmit(props.question.answer ?? undefined);
				}
			}
		}
	}, [props.isLastQuestion, answerChoices, answerText, answerId]);

	return (
		<S.Wrapper ref={focusLastActivity}>
			<ActivityBubbleTitle
				title={
					<>
						{compiler(question.question.title, {
							overrides: {
								p: {
									component: ({ children, ...props }) => <p {...props}>{children}</p>,
									props: {
										'data-texttype': 'markdown'
									}
								},
								img: {
									component: ({ children, ...props }) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
								}
							}
						})}
					</>
				}
				modalOpen={props.modalOpen}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity && !!props.isLastQuestion}
				disabled={props.disabled}
				stepNumber={props.stepNumber}
				stepperStyle={!props.isLastQuestion || !props.isLastActivity ? 'secondary' : 'primary'}
				style={{ padding: 0 }}
			/>
			{question.question.type === 'Open' && (
				<TextArea
					style={{ marginTop: themeContext.spacings.smallMedium }}
					$label=""
					value={answerText}
					name={`cvv-${question.question.id}`}
					disabled={props.disabled}
					onChange={handleSetComment}
				/>
			)}
			{question.question.type === 'Number' && (
				<SP.Col style={{ marginTop: themeContext.spacings.smallMedium }}>
					<InputNumber autoFocus={!props.disabled} onChange={handleSetNumber} disabled={props.disabled} style={{ maxWidth: 100 }} />
				</SP.Col>
			)}
			{question.question.type === 'MultipleChoice' && (
				<InputChoices name="CPAnswerChoices" choices={choices} disabled={props.disabled} onChange={handleChoiceChange} />
			)}
			{sendStatus && (
				<>
					{question.question.type === 'Number' && (!answerText || answerText.length === 0) && (
						<SP.RowClean style={{ paddingTop: themeContext.spacings.small }}>
							<SP.Error>{getLabel('ConsultationPreparationErrorNumber', settings.applicationTexts, true)}</SP.Error>
						</SP.RowClean>
					)}
					{question.question.type === 'MultipleChoice' && answerChoices.length === 0 && (
						<SP.RowClean style={{ paddingTop: themeContext.spacings.small }}>
							<SP.Error>{getLabel('ConsultationPreparationErrorMultipleChoice', settings.applicationTexts, true)}</SP.Error>
						</SP.RowClean>
					)}
				</>
			)}
			{question.question.type === 'Normal' && (
				<S.ConsultationPreparationAnswerButtons style={{ marginTop: themeContext.spacings.smallMedium }}>
					{answers.map((answer) => (
						<SIC.CheckBox key={answer.id} checked={props.question.answer?.answerId === answer.id ? true : false}>
							<SP.Field
								type="radio"
								name={`consultation_${question.question.id}`}
								id={`${question.question.id}_${answer.id}`}
								disabled={props.disabled}
								onClick={() => handleActivityResponse(answer.id)}
							/>
							<SP.Label htmlFor={`${question.question.id}_${answer.id}`}>{answer.title}</SP.Label>
						</SIC.CheckBox>
					))}
				</S.ConsultationPreparationAnswerButtons>
			)}

			{answerChanged && !props.isLastQuestion && (
				<S.ConfirmButtonWrapper>
					<S.Button
						disabled={answerText === '' && answerChoices.length === 0}
						onClick={() => void handleSubmit({ answerId: answers[0].id, answerText, answerChoices })}
					>
						{getLabel('WidgetConfirmButton', settings.applicationTexts)}
					</S.Button>
				</S.ConfirmButtonWrapper>
			)}
		</S.Wrapper>
	);
};

export default ConsultationPreparationQuestion;
