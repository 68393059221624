import React from 'react';
import styled from 'styled-components';
import type { DivProps } from '../../models/widget/Styled';

const App = styled.div`
	&&& {
		position: relative;
		font-size: 16px;
		margin: 0;
		font-family: ${(props) => props.theme.fonts.primary};
		height: 100%;
		width: 100%;

		* {
			font-family: ${(props) => props.theme.fonts.primary};

			&::selection {
				background: ${(props) => props.theme.colors.accent300};
			}
		}
	}
`;

const StartButton = styled.div``;

const Loader = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default {
	App: (props: DivProps) => (
		<App {...props} data-minddclass="empathy">
			{props.children}
		</App>
	),
	StartButton: (props: DivProps) => (
		<StartButton {...props} data-minddclass="startbutton">
			{props.children}
		</StartButton>
	),
	Loader: (props: DivProps) => (
		<Loader {...props} data-minddclass="loader">
			{props.children}
		</Loader>
	)
};
